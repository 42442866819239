import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from "config";
import GuestHeader from './GuestHeader';
import OuterContainer from './OuterContainer';
import TextSection from './TextSection';
import PropertyDetails from './PropertyDetails';
import Services from './Services';

const CheckInInstructions = () => {
    const { uuid } = useParams();
    const [time, setTime] = useState('');
    const [checkInTime, setCheckInTime] = useState('');
    const [timeOptions, setTimeOptions] = useState([]);
    const [propertyDetails, setPropertyDetails] = useState('')

    useEffect(() => {
        const fetchCheckInTime = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/bookings/${uuid}/get_checkin_time`);
                const responseBookings = await axios.get(`${BASE_URL}/bookings/${uuid}`);
                console.log(responseBookings, 'responseBookings')
                setPropertyDetails(responseBookings.data.property_details)
                const checkInTime = response.data;
                const formattedTime = formatAMPM(checkInTime);
                setCheckInTime(formattedTime);
                setTimeOptions(generateTimeOptions(checkInTime));
                setTime(formattedTime);
            } catch (error) {
                console.error('Error fetching check-in time:', error);
            }
        };

        fetchCheckInTime();
    }, [uuid]);

    const generateTimeOptions = (startTime) => {
        const times = [];
        const [startHour, startMinute] = startTime.split(':').map(Number);

        for (let hour = startHour; hour < 24; hour++) {
            for (let minute = (hour === startHour ? startMinute : 0); minute < 60; minute += 30) {
                const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
                times.push(formattedTime);
            }
        }

        return times;
    };

    const formatAMPM = (time) => {
        let [hour, minute] = time.split(':');
        hour = parseInt(hour, 10);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12; // convert hour to 12-hour format
        return `${hour}:${minute} ${ampm}`;
    };

    const instructions = propertyDetails.check_in_instructions?.split('\n') || [];

    return (
        <OuterContainer>
               <Card
                sx={{
                    borderRadius: 1.5,
                    boxShadow: 2,
                    mt: 3,
                    // mx: { xs: 1, sm: 2, md: 3 },
                    ml: { xs: 0,lg:0},
                    // mr: { xs: 7, sm: 3 },
                    width: { xs: '100%', sm: '100%', md: '100%' },
                    maxWidth: 580,
                    height: { xs: '380', sm: '500' },
                    p: 2,
                }}
            >
                <CardContent sx={{ ml:-2 }}>
                    <Typography variant="h5" component="div" gutterBottom>
                        Check In Instructions
                    </Typography>
                    <Box sx={{ borderBottom: '1px solid #e0e0e0', mb: 1 }} />
                    <Typography variant="body1">
                        <strong>Your Check in Time: {checkInTime ? checkInTime : ''}</strong>
                    </Typography>
                    <ul style={{ paddingLeft: 23.5 }}>
                        {instructions.map((instruction, index) => (
                            <li key={index}>{instruction}</li>
                        ))}
                    </ul>
                </CardContent>
            </Card>
        </OuterContainer>
    );
};

export default CheckInInstructions;
