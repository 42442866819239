import React, { useState, useEffect } from 'react';
import OuterContainer from './OuterContainer';
import PreHeader from './PreHeader';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Button, Box, Tooltip } from "@mui/material";
import BookingDetailsCheckIn from './BookingDetails';
import CustomButton from './CustomButton';
import useBookingDetails from './useBookingDetails';
import Amenities from './Amenities';
import CheckInInstructions from './CheckinInstructions';
import { useParams, useNavigate } from 'react-router-dom';
import PropertyDetails from './PropertyDetails';
import Services from './Services';
import { BASE_URL } from 'config';
import axios from 'api/axios';
import TextSection from './TextSection';

function PreCheckinSmart() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const currentTime = new Date();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [checkedIn, setCheckedIn] = useState(false);
  const [keyStatus, setKeyStatus] = useState(false);
  const [checkinTime, setCheckinTime] = useState(null);
  const [checkoutTime, setCheckoutTime] = useState(null);

  const fetchBookingDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/bookings/${uuid}`);
      if (response.status === 200) {
        setBookingDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  useEffect(() => {
    fetchBookingDetails();
  }, [uuid]);

  useEffect(() => {
    if (bookingDetails) {
      const newCheckinTime = new Date(`${bookingDetails.start_date}T${bookingDetails.checkin_time}`);
      const newCheckoutTime = new Date(`${bookingDetails.end_date}T${bookingDetails.checkout_time}`);
      setCheckinTime(newCheckinTime);
      setCheckoutTime(newCheckoutTime);

      if (bookingDetails.is_checked_in) {
        setKeyStatus(true);
      }
    }
  }, [bookingDetails]);

  if (!bookingDetails) {
    return null;
  }

  const Xhours = bookingDetails.property_details.Xhours * 60 * 60 * 1000;
  const isCheckinEnabled = (checkinTime - currentTime <= Xhours);

  const handlePayment = () => {
    navigate(`/bookings/${uuid}/paydeposit`);
  }

  const handleCheckIn = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/bookings/${bookingDetails.id}/checkin-status`, { is_checked_in: true });
      if (response.status === 200) {
        setCheckedIn(true);
        setKeyStatus(true);
        await fetchBookingDetails();  // Refresh booking details
      }
    } catch (error) {
      console.error("Error updating check-in status:", error);
    }
  };

  let actionButton = null;

  if (bookingDetails.deposit_amount === "0.00") {
    if (isCheckinEnabled && !checkedIn && !bookingDetails.is_checked_in) {
      actionButton = <CustomButton text={"Check In"} onClick={handleCheckIn} />;
    } else if (checkedIn || bookingDetails.is_checked_in) {
      actionButton = <CustomButton text={"Check Out"} />;
    } else if (!isCheckinEnabled) {
      actionButton = <CustomButton text={"Check In"} disabled />;
    }
  } else {
    if (bookingDetails.payment_details.status === "PAID") {
      if (isCheckinEnabled && !checkedIn && !bookingDetails.is_checked_in) {
        actionButton = <CustomButton text={"Check In"} onClick={handleCheckIn} />;
      } else if (checkedIn || bookingDetails.is_checked_in) {
        actionButton = <CustomButton text={"Check Out"} />;
      } else {
        actionButton = <CustomButton text={"Check In"} disabled />;
      }
    } else {
      actionButton = <CustomButton onClick={handlePayment} text={"Pay Deposit"} />;
    }
  }

  return (
    <OuterContainer>
      <PreHeader />
      <Box sx={{ mt: 1,  ml: { md: 6, lg:4}}} >
      <TextSection>
        <Box>
          <Box>
            <BookingDetailsCheckIn hideExtraDetails={true} keyStatus={keyStatus} entryKey={bookingDetails.property_details.has_smart_lock} />
          </Box>
          {!keyStatus && <CheckInInstructions />}
          <Box mt={4} sx={{ display: "flex", justifyContent: { xs: 'center', md: 'flex-start' } }}>
            {actionButton}
          </Box>
          <PropertyDetails propertydetails={bookingDetails?.property_details.property_description} />
          <Amenities bookingDetails={bookingDetails} />
          <Services checkedIn={checkedIn} bookingDetails={bookingDetails} isCheckinEnabled={isCheckinEnabled} uuid={uuid} />

          <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
            <Tooltip title="Chat with us" arrow>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  minWidth: "auto",
                  width: 56,
                  height: 56,
                  borderRadius: "50%",
                }}
              >
                <ChatBubbleOutlineIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </TextSection>
      </Box>
    </OuterContainer>
  );
}

export default PreCheckinSmart;
