import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorageRoles } from "utils/utilityFunctions";
const Home = ()=> {
    const navigate= useNavigate();
    const { role, setRole } = useAuth();
    useEffect( ()=> {
        let localRole = role;
        if (localRole === "") {

            localRole = getLocalStorageRoles();
            if(localRole) {
              setRole(localRole);
            }
        }
        if (localRole === "PARTNER") {
            navigate("/jobs");
        }
        else {
            navigate("/bookings");
        }
    },[role, navigate, setRole])
    return (
    <>
        <p>
            Home
        </p>
    </>
    );

}

export default Home;