
import { useState, useEffect } from 'react';
import axios from 'api/axios';
import { BASE_URL } from 'config';

import { useParams } from 'react-router-dom';
const useBookingDetails = () => {
  const { uuid } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bookings/${uuid}`);
        setBookingDetails(response.data);
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };
    fetchBookingDetails();
  }, [uuid]);

  return bookingDetails;
};

export default useBookingDetails;
