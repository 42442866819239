import React, { useState } from 'react'
import { Container, Grid, TextField, Typography, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomErrorBox from "components/ErrorAlert";

const initialEditFeedback = {
    overall_satisfaction: '',
    cleanliness: '',
    communication: '',  
    service_quality: '',
    value_for_money: '',
    comments: '',
};

const CustomerFeedback = ({uuid, handleCustomerExistState}) => {
    const [editFeedback, setEditFeedback] = useState(initialEditFeedback);
    const [error, setError] = useState("");
    const axios = useAxiosPrivate();


    const handleChange = (e) => {
        setError("");
        setEditFeedback({ ...editFeedback, [e.target.name]: e.target.value });
    }


    const handleFeedbackSubmit = async (event) => {
        event.preventDefault();
        if (
            !editFeedback.overall_satisfaction ||
            !editFeedback.cleanliness ||
            !editFeedback.communication ||
            !editFeedback.service_quality ||
            !editFeedback.value_for_money ||
            !editFeedback.comments
        ) {
            setError("All fields are required");
            return;
        }
        const data = {
            ...editFeedback,
            // booking: uuid,
        }

        try {
            const response = await axios.post(`bookings/${uuid}/feedbacks/`, data);
            console.log(response.data);
            // When Feedback Submitted Successfully
            // Move the customer to next step, i.e, Booking Complete
            handleCustomerExistState(4);
            setEditFeedback(initialEditFeedback);
        } catch (error) {
            console.log(error);
            if(error.response?.data?.detail)
            {
                setError(error.response.data.detail);
            } else {
                setError("Failed to submit feedback. Please try again!");
            }
        }
        
    };
    return (
        <>
            <Container maxWidth="md">
                <Typography variant="h4">Feedback</Typography>
                
                <form onSubmit={handleFeedbackSubmit}>
                    <Grid container sx={{margin:"30px 0"}}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Overall Satisfaction:</Typography>                   
                            <RadioGroup name="overall_satisfaction" value={editFeedback.satisfaction} onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                                <FormControlLabel value="Very Satisfied" control={<Radio />} label="Very Satisfied" />
                                <FormControlLabel value="Satisfied" control={<Radio />} label="Satisfied" />
                                <FormControlLabel value="Neutral" control={<Radio />} label="Neutral" />
                                <FormControlLabel value="Dissatisfied" control={<Radio />} label="Dissatisfied" />
                                <FormControlLabel value="Very Dissatisfied" control={<Radio />} label="Very Dissatisfied" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Cleanliness:</Typography>                   
                            <RadioGroup name="cleanliness" value={editFeedback.cleanliness} onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                                <FormControlLabel value="Excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel value="Good" control={<Radio />} label="Good" />
                                <FormControlLabel value="Average" control={<Radio />} label="Average" />
                                <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                                <FormControlLabel value="Very Poor" control={<Radio />} label="Very Poor" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Communication:</Typography>
                            <RadioGroup name="communication" value={editFeedback.communication} onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                                <FormControlLabel value="Excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel value="Good" control={<Radio />} label="Good" />
                                <FormControlLabel value="Average" control={<Radio />} label="Average" />
                                <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                                <FormControlLabel value="Very Poor" control={<Radio />} label="Very Poor" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Service Quality:</Typography>
                            <RadioGroup name="service_quality" value={editFeedback.serviceQuality} onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                                <FormControlLabel value="Excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel value="Good" control={<Radio />} label="Good" />
                                <FormControlLabel value="Average" control={<Radio />} label="Average" />
                                <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                                <FormControlLabel value="Very Poor" control={<Radio />} label="Very Poor" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Value for Money:</Typography>
                            <RadioGroup name="value_for_money" value={editFeedback.valueForMoney} onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                                <FormControlLabel value="Excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel value="Good" control={<Radio />} label="Good" />
                                <FormControlLabel value="Average" control={<Radio />} label="Average" />
                                <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                                <FormControlLabel value="Very Poor" control={<Radio />} label="Very Poor" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                margin="dense"
                                label="Comments"
                                fullWidth
                                multiline
                                rows={4}
                                name="comments"
                                value={editFeedback.comments}
                                onChange={handleChange}
                                required
                                sx={{margin:"50px 0"}}
                            />
                        </Grid>
                    </Grid>
                    {error && <CustomErrorBox errorMessage={error} />}
                    <Button type="submit" variant="contained">Submit Feedback</Button>
                </form>
            </Container>
        </>
    );
}
export default CustomerFeedback;