import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link as RouterLink } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Dot from "components/@extended/Dot";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomSnackbar from "components/Snackbar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Headings of Bookings Table
var headCells = [
  {
    id: "id",
    align: "center",
    disablePadding: false,
    label: "ID",
  },
  {
    id: "booking",
    align: "center",
    disablePadding: false,
    label: "Booking",
  },
  {
    id: "actual_date",
    align: "left",
    disablePadding: true,
    label: "Actual Date",
  },
  {
    id: "actual_time",
    align: "left",
    disablePadding: false,
    label: "Actual Time",
  },
  {
    id: "requested_date",
    align: "left",
    disablePadding: false,
    label: "Requested Date",
  },
  {
    id: "requested_time",
    align: "left",
    disablePadding: false,
    label: "Requested Time",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    align: "center",
    disablePadding: false,
    label: "Action",
  },
];

// Function Return Color Dots Based on Status
const OrderStatus = ({ status }) => {
  let color;

  switch (status) {
    case "UPCOMING":
      color = "warning";
      break;
    case "ACTIVE":
      color = "success";
      break;
    case "DELETED":
      color = "error";
      break;
    default:
      color = "primary";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{status}</Typography>
    </Stack>
  );
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isCustomer } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            // When to Show properties in the Booking Table or not.
            !(isCustomer && (headCell.id === "booking" || headCell.id === "action" || headCell.id === "id")) && (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}>
                {/* When Heading is UUID and Action then dont Allow Sort */}
                {headCell.id === "uuid" || headCell.id === "action" ? (
                  headCell.label
                ) : (
                  <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function LateCheckoutDatagrid(props) {
  const rows = props.lateRequests;
  // To show properties or not
  const { isCustomer } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // To show error messages in snackbar
  const [errorMessage, setErrorMessage] = useState("");
  // TO show success messages in snackbar
  const [successMessage, setSuccessMessage] = useState("");
  // To make API calls at protected endpoints
  const axios = useAxiosPrivate();

  const handleSnackbarClose = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );

  async function handleLateRequestApprove(id) {
    try {
      const lateRequestResponse = await axios.patch(`/bookings/approve-late-checkout-owner/${id}/`);
      console.log("Late Request Submit Response: ",lateRequestResponse);
      setSuccessMessage("Late Checkout Request approved!");
      props.onLateRequestsChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Approving the request.");
      }
    }
  }
  
  async function handleLateRequestDecline(id) {
    try {
      const lateRequestResponse = await axios.patch(`/bookings/decline-late-checkout-owner/${id}/`);
      console.log("Late Request Submit Response: ",lateRequestResponse);
      setSuccessMessage("Late Checkout Request declined!");
      props.onLateRequestsChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Declining the request.");
      }
    }
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", pl: 1, mb: 2 }}>
          <CustomSnackbar message={successMessage} closeSnackbar={handleSnackbarClose} severity="success" />
          <CustomSnackbar message={errorMessage} closeSnackbar={handleSnackbarClose} severity="error" />
          

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <EnhancedTableHead order={order} orderBy={orderBy} isCustomer={isCustomer} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {!isCustomer && (
                        <TableCell align="center">{row.id}</TableCell>
                      )}
                      {!isCustomer && (
                        <TableCell align="center">
                          {row.booking}
                          <Link color="primary" component={RouterLink} to={`/bookings/${row.booking}`} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                            <OpenInNewIcon fontSize="small" style={{ marginLeft: "4px", verticalAlign: "middle" }} />
                          </Link>
                        </TableCell>
                      )}
                      <TableCell align="left">{row.original_end_date}</TableCell>
                      <TableCell align="left">{row.original_checkout_time}</TableCell>
                      <TableCell align="left">{row.requested_end_date}</TableCell>
                      <TableCell align="left">{row.requested_checkout_time}</TableCell>
                      
                      <TableCell align="left">
                        <OrderStatus status={row.status} />
                      </TableCell>
                      {!isCustomer && (
                        <TableCell align="center">
                          { row.status === "REQUESTED" && (
                              <>
                                  <Button size="small" onClick={() => handleLateRequestApprove(row.id)} color="success" sx={{margin : "4px"}} variant="contained">
                                      Approve
                                  </Button>
                                  <Button size="small" onClick={() => handleLateRequestDecline(row.id)}  color="error" sx={{margin : "4px"}} variant="contained">
                                      Decline
                                  </Button>
                              </>
                          )}
                          
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      
        
    </>
  );
}
