import { Outlet } from "react-router-dom";

// material-ui
import { Box } from "@mui/material";
import useAuth from "hooks/useAuth";
import { getLocalStorageRoles } from "utils/utilityFunctions.js";
// project import
import TopBar from "./TopBar.js";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  
  const { role } = useAuth();
  var userRole = role;
  if (Object.keys(userRole).length === 0) {
    var localRole = getLocalStorageRoles();
    // check role available in local storage
    if (localRole) {
        userRole = localRole;
    }
  }
  return (
    <>
      <TopBar userRole={userRole} />
      <Box component="main" sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        {/* <Toolbar /> */}
        {/* <Breadcrumbs navigation={navigation} /> */}
        <Outlet />
      </Box>
    </>
  );
};

export default MainLayout;
