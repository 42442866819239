import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PayDeposit from './PayDeposit'; // Adjust the import based on your file structure

const stripePromise = loadStripe('pk_test_51OHNSwDTiGYmJ4THjxCNZj2ddTFPWL1FWKLo1LCAanyFrX60o6k82zZvINekODRdigGwFDtJhQtKwL73N6zvRvNC00p1ocEp0h');

const PayDepositWrapper = () => (
    <Elements stripe={stripePromise}>
        <PayDeposit />
    </Elements>
);

export default PayDepositWrapper;
