import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/authentication/Login";
import Properties from "./pages/properties/Properties";
import Bookings from "./pages/bookings/Bookings";
import RequireAuth from "./components/RequireAuth";
import Layout from "./layout/Layout";
import Property from "./pages/properties/Property";
import MainLayout from "./layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout/index";
import ThemeCustomization from "themes";
import Booking from "pages/bookings/Booking";
import Jobs from "pages/jobs/Jobs";
import Customer from "pages/customer-client/Customer";
import IndividualCustomer from "pages/customers/Customer";
import Unauthorized from "pages/errors/Unauthorized";
import Customers from "pages/customers/Customers";
import SpecialRequest from "pages/guest-portal/SpecialRequest";
import WelcomePage from "pages/guest-portal/WelcomePage";
import DocumentUpload from "pages/guest-portal/DocumentUpload";
import Review from "pages/guest-portal/Review";
import ArrivalTime from "pages/guest-portal/ArrivalTime";
import PayDeposit from "pages/guest-portal/PayDeposit";
import PayDepositWrapper from "pages/guest-portal/PayDepositWrapper";
import CheckoutForm from "pages/customer-client/CheckoutForm";
import ThankeyCard from "pages/guest-portal/ThankeyCard";
import CheckInInstructions from "pages/guest-portal/CheckinInstructions";

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import PreCheckinSmart from "pages/guest-portal/PreCheckinSmart";
import EntryKey from "pages/guest-portal/EntryKey";
import MagicLinkStatus from "pages/guest-portal/MagicLinkStatus";


function App() {
  return (
    <>
      <ToastContainer />
      <ThemeCustomization>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route exact path="/login" element={<Login />} />


              {/* public routes */}
              {/* <Route element={<MinimalLayout />}> */}
              <Route>
                <Route exact path="/unauthorized" element={<Unauthorized />} />
                <Route exact path="/customer/:uuid" element={<MagicLinkStatus />} />
                <Route exact path="/details/:uuid" element={<Customer />} />
                <Route exact path="/customer/:uuid/welcome" element={<WelcomePage />} />
                <Route exact path="/bookings/:uuid/document-upload" element={<DocumentUpload />} />
                <Route exact path="/bookings/:uuid/review-booking" element={<Review />} />
                <Route exact path="/bookings/:uuid/arrival-time" element={<ArrivalTime />} />
                <Route exact path="/bookings/:uuid/special-request" element={<SpecialRequest />} />
                <Route exact path="bookings/:uuid/paydeposit" element={<PayDepositWrapper />} />
                <Route exact path="bookings/:uuid/thanks" element={<ThankeyCard />} />
                <Route exact path="bookings/:uuid/precheckin" element={<PreCheckinSmart />} />
              </Route>
              {/* private routes */}
              <Route element={<MainLayout />}>
                <Route element={<RequireAuth allowedRoles={["OWNER"]} />}>¸
                  <Route exact path="/properties" element={<Properties />} />
                  <Route exact path="/bookings" element={<Bookings />} />
                  <Route exact path="/customers" element={<Customers />} />
                  <Route path="/properties/:id" element={<Property />} />
                  <Route path="/bookings/:id" element={<Booking />} />
                  <Route path="/customers/:id" element={<IndividualCustomer />} />
                </Route>
                <Route
                  element={<RequireAuth allowedRoles={["OWNER", "PARTNER"]} />}
                >
                  <Route exact path="/" element={<Home />} />
                  <Route path="/jobs" element={<Jobs />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeCustomization>
    </>
  );
}

export default App;
