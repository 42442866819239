

import React, { useState, useEffect } from 'react';
import axios from 'api/axios';
import { useParams } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import notification from '../../images/notification.svg';
import { BASE_URL } from "config";
const useStyles = {
  drawerPaper: {
    backgroundColor: '#004AAD',
    width: '100%',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px',
    color: '#FCFCFC',
  },
};
const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [guestName, setGuestName] = useState('');
  const { uuid } = useParams();
  // useEffect(() => {
  //   const fetchGuestName = async () => {
  //     try {
  //       const response = await axios.fetchGuestName(`${BASE_URL}/bookings/${uuid}/guest-name`);
  //       const data = await response.json();
  //       console.log()
  //       setGuestName(data.name);
  //     } catch (error) {
  //       console.error('Error fetching guest name:', error);
  //     }
  //   };
  //   fetchGuestName();
  // }, []);
  useEffect(() => {
    const fetchGuestName = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bookings/${uuid}/guest-name`);
        setGuestName(response.data);
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };
    fetchGuestName();
  }, [uuid]);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };
  return (
    <Box>
      <AppBar position="static"  sx={{ backgroundColor: '#004AAD',pt: 3, pb: 3,pl:2,pr:2 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr:2 }}
          >
          {/* changes made here */}
            <MenuIcon sx={{ fontSize: '40px', ml: { md: 4, lg:20} }} />
          </IconButton>
           {/* changes made here */}
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 , ml: { md: 6, lg:10}}}>
            Hi, {guestName.Guest_name}
          </Typography>
          <img src={notification} alt='' style={{ height: '30px' }} />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: useStyles.drawerPaper,
        }}
      >
        <Box sx={useStyles.closeIcon}>
          <IconButton onClick={toggleDrawer(false)} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {['Home', 'Your Details', 'Check Out', 'Requests', 'Payment', 'Support'].map((text) => (
            <ListItem button key={text} onClick={toggleDrawer(false)}>
              <ListItemText primary={text} sx={{ fontSize: '24px', color: '#FFFFFF', fontWeight: '600' }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};
export default HamburgerMenu;
