// BookingDetails.js
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
} from "@mui/material";

import calenderImg from "../../images/calender.svg";
import guestImg from "../../images/guest.svg";
import entryKeyIcon from "../../images/entryKeyIcon.svg";
import locationImg from "../../images/location.svg";
import entryKeyIcon2 from "../../images/entryKeyIcon2.svg";
import useBookingDetails from "./useBookingDetails";

const BookingDetailsCheckIn = ({ hideExtraDetails, keyStatus ,entryKey }) => {
  const bookingDetails = useBookingDetails();
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = formattedDate.split(' ');
    const dayWithSuffix = `${parseInt(day)}${getDaySuffix(parseInt(day))}`;
    return `${dayWithSuffix} ${month}, ${year}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  console.log(entryKey,'ttt')

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(':');
    const date = new Date();
    console.log(date, 'date')
    date.setHours(hour);
    date.setMinutes(minute);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  if (!bookingDetails) {
    return  <Paper
    elevation={2}
    sx={{ p: 15, width: "100%", maxWidth: "580px" }}
  >Loading...</Paper> // or a loading spinner
  }
  // m: "-6% 4.9% 0%"
  return (
    <Box>
      <Paper
        elevation={2}
        sx={{ p: 3, width: "100%", maxWidth: "580px" }}
      >
        <Typography
          variant="h4"
          align="left"
          sx={{ mb: 2, zIndex: -1, color: "#3D3D3D" }}
        >
          Your Booking Details
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <img src={locationImg} alt="Location" />
            </Grid>
            <Grid item>
              <Typography align="left" variant="h4"  sx={{fontSize:{xs:'16px', md:'20px '}}}>
                {bookingDetails.property_details.property_name}
              </Typography>
              <Typography variant="subtitle2"  sx={{fontSize: {xs:'12px', md:'16px'},  color: '#6B6B6B', fontWeight: "400"}}>
                {bookingDetails.property_details.apt_number}, {bookingDetails.property_details.neighbourhood}, {bookingDetails.property_details.building_name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <img src={calenderImg} alt="Calender" />
              <Box>
                <Typography align="left" variant="h4" sx={{fontSize:{xs:'16px', md:'20px '}}}>Check In</Typography>
                <Typography align="left"  variant="subtitle2" style={{ color: '#6B6B6B' }}  sx={{fontSize: {xs:'12px', md:'16px '} , fontWeight: "400"}}>{formatDate(bookingDetails.start_date)}</Typography>
                <Typography align="left"  variant="subtitle2" style={{ color: '#6B6B6B' }}  sx={{fontSize: {xs:'12px', md:'16px'}, fontWeight: "400"}}>{formatTime(bookingDetails.checkin_time)}</Typography>
              </Box>
              <Box sx={{ borderLeft: "1px solid #DDDBDB", paddingLeft: "16px"  }}>
                <Typography align="left" variant="h4" sx={{fontSize:{xs:'16px', md:'20px '}}}>Check Out</Typography>
                <Typography align="left"  variant="subtitle2" style={{ color: '#6B6B6B' }} sx={{fontSize: {xs:'12px', md:'16px'} , fontWeight: "400"}} >{formatDate(bookingDetails.end_date)}</Typography>
                <Typography align="left"  variant="subtitle2"  style={{ color: '#6B6B6B' }}  sx={{fontSize: {xs:'12px', md:'16px'}, fontWeight: "400"}}>{formatTime(bookingDetails.checkout_time)}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {!keyStatus && !hideExtraDetails && (
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item>
                <img src={guestImg} alt="Guest" />
              </Grid>
              <Grid item>
                <Typography align="left" variant="h4"  sx={{fontSize:{xs:'16px', md:'20px '}}}>
                  Guests: <span  variant="subtitle2" style={{ color: "#6B6B6B" }}  sx={{fontSize: {xs:'12px', md:'16px'} , fontWeight: "400"}}>{bookingDetails.num_guests}</span>
                  <br />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
      {hideExtraDetails && entryKey && (
        <Paper
          elevation={2}
          sx={{ p: 1, width: "100%", maxWidth: "580px", borderTop: 1, borderColor: "#DEDEDE" }}
        >
          {!bookingDetails?.has_smart_lock === !keyStatus?
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <img src={entryKeyIcon} alt="entry key" />
              </Grid>
              <Grid item>
                <Typography align="center" variant="subtitle1" sx={{ color: "#DEDEDE" }}>
                  Entry Key </Typography>
              </Grid>
            </Grid>
            :
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <img src={entryKeyIcon2} alt="entry key" />
              </Grid>
              <Grid item>
                <Typography align="center" variant="subtitle1" sx={{ color: "#004AAD" }}>
                  Entry Key :  {`#${bookingDetails.door_key}`}
                  <br />
                </Typography>
              </Grid>
            </Grid>}

        </Paper>
      )}
    </Box>
  );
};

export default BookingDetailsCheckIn;
