import React, { useState } from 'react';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';

const ThankeyCard = () => {
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(true);

  const handlePayment = () => {
    // Your payment logic here
    // ...

    // For demonstration, we'll assume the payment is successful
    setIsPaymentSuccessful(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f7f7f7"
    >
      {!isPaymentSuccessful ? (
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handlePayment}>
            Make Payment
          </Button>
        </Box>
      ) : (
        <ThankYouCard />
      )}
    </Box>
  );
};

const ThankYouCard = () => {
  return (
    <Card
      style={{
        textAlign: 'center',
        padding: '20px',
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <CardContent>
        <Typography variant="h4" component="h1" color="primary">
          Thank You!
        </Typography>
        <Typography variant="body1" component="p" color="textSecondary">
        you've successfully checked out and are ready for your next destination.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ThankeyCard;
