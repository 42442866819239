import React from 'react';
import Typography from '@mui/material/Typography';

const TextHeading = ({ text }) => {
  return (
    // changes made here
    <Typography  sx={{ color: '#3D3D3D', mb: 2, mt:3, fontWeight:600, fontSize: {xs:'16px', md:'20px'}}}>
      {text}
    </Typography>
  );
};

export default TextHeading;
