import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { getLocalStorageTokens, getLocalStorageRoles } from "../utils/utilityFunctions";


const RequireAuth = ({allowedRoles}) => {
    /* Component to check Authentication and Permissions */
    const location = useLocation();
    // get auth and role from useAuth context provider custom hook
    const { auth, role } = useAuth();
    var accessToken = auth;
    var userRole = role;
    if (Object.keys(accessToken).length === 0) {
        // If the access token is not present in auth state
        // Fetch it from local storage
        var localToken = getLocalStorageTokens();
        // check access_token available in local storage
        if (localToken) {
            accessToken = localToken;
        }
    }
    if (Object.keys(userRole).length === 0) {
        // If the role is not present in role state
        // Fetch it from local storage
        var localRole = getLocalStorageRoles();
        // check role available in local storage
        if (localRole) {
            userRole = localRole;
        }
    }
    var userAuth = false;
    // Whether user is authenticated or not
    if (accessToken && accessToken.length > 0) {
        userAuth = true;
    }
    

    return(
        // If user has the role to access this end point then allowed
        allowedRoles?.includes(userRole)
            ? <Outlet /> 
            : userAuth //Else when user does not has the requied role but is authenticated
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                    // Show unauthorized page
                :<Navigate to="/login" state={{ from: location }} replace />
                    // Else navigate to login page
    );

}
export default RequireAuth;