export function setLocalStorageTokens(tokens) {
  localStorage.setItem("admin_token", tokens);
}
export function setLocalStorageRoles(role) {
  localStorage.setItem("admin_role", role);
}

export function getLocalStorageTokens() {
  const localTokens = localStorage.getItem("admin_token");
  if (localTokens) {
    return localTokens
  }
  return {};
}
export function getLocalStorageRoles() {
  const localRole = localStorage.getItem("admin_role");
  if (localRole) {
    return localRole
  }
  return {};
}

export const formatDate = (dateString) => {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

export const formatTime = (timeString) => {
  const time = new Date(`2000-01-01T${timeString}`);
  return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
    return true
  } catch {
    console.log("Clipboard API not supported, using fallback method");
    fallbackCopyToClipboard(text);
  }
};
const fallbackCopyToClipboard = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed";
  document.body.appendChild(textarea);

  textarea.select();
  try {
    document.execCommand("copy");
    document.body.removeChild(textarea);
    return true
  } catch {
    console.log("Clipboard fallback not working as well. Can't copy.");
    document.body.removeChild(textarea);
    return false
  }

};