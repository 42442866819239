import React ,{useState} from "react";
import OuterContainer from "./OuterContainer";
import TextSection from "./TextSection";
import GuestHeader from "./GuestHeader";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { BASE_URL } from "config";
import axios from "api/axios";
import CustomButton from "./CustomButton";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import useBookingDetails from './useBookingDetails';
import {
    Box,
    Typography,
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Paper,
    Tooltip,
    IconButton,
} from "@mui/material";
function SpecialRequest() {
    const bookingDetails = useBookingDetails();
    console.log(bookingDetails?.deposit_amount,'depositAmount')
    const [specialRequest, setSpecialRequest] = useState('');
    const { uuid } = useParams();
    const navigate = useNavigate();
    const handleInputChange = (e) => {
        setSpecialRequest(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/bookings/${uuid}/special-request`, {
                request: specialRequest,
            });
            console.log('Request successful:', response);
            // Navigate to the next step or show a success message
            if(bookingDetails?.deposit_amount>0.00){
                navigate(`/bookings/${uuid}/paydeposit`);
            }else{
                navigate(`/bookings/${uuid}/precheckin`);
            }
        } catch (error) {
            console.error('Error submitting request:', error);
        }
    };
    const handleSkip=()=>{
        if(bookingDetails?.deposit_amount>0.00){
            navigate(`/bookings/${uuid}/paydeposit`);
        }else{
            navigate(`/bookings/${uuid}/precheckin`);
        }
    }
    return (
        <OuterContainer>
            <GuestHeader link={`/bookings/${uuid}/arrival-time`} />
            <TextSection
                title="Special Requests"
                description="Special requests cannot be guaranteed – but the property will do its best to meet your needs."
            >
                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        id="special-request"
                        placeholder="Your Request"
                        variant="outlined"
                        value={specialRequest}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#fff',
                                '& fieldset': {
                                    borderColor: '#ccc',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#bbb',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#aaa',
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: '#000',
                                },
                            },
                            borderRadius: '40px',
                            // Maintain full width on small screens
                            '@media (max-width: 600px)': {
                                width: '100%', width: '100%', maxWidth: '500px',
                            },
                            // for larger screens
                            '@media (min-width: 992px)': {
                                maxWidth: '630px',
                            }
                        }}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start"
                    sx={{
                        '@media (max-width: 500px)': {
                            justifyContent: 'center'
                        },
                    }} mt={3}>
                    <Box sx={{ mr: 4 }}>
                        <CustomButton disabled={!specialRequest} onClick={handleSubmit} text="submit" />
                    </Box>
                    <CustomButton onClick={handleSkip} text="skip" />
                </Box>
                <Box>
                </Box>
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4, fleGrow: 1 }}>
                    <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
                        <Tooltip title="Chat with us" arrow>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    minWidth: "auto",
                                    width: 56,
                                    height: 56,
                                    borderRadius: "50%",
                                }}
                            >
                                <ChatBubbleOutlineIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                </Container>
            </TextSection>
        </OuterContainer>
    )
}
export default SpecialRequest;