import React, { useState, useEffect, useRef, useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Typography,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  Backdrop,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  Link,
  Breadcrumbs,
} from "@mui/material";

import BookingsDataGrid from "pages/bookings/BookingsDataGrid";
import LateCheckoutDatagrid from "pages/bookings/LateCheckoutDatagrid";
import { Link as RouterLink } from "react-router-dom";

import CustomErrorBox from "components/ErrorAlert";

const Bookings = () => {
  const BOOKINGS_URL = "/bookings/";
  const PROPERTIES_URL = "/properties/";
  // To store all Bookings list
  const [bookings, setBookings] = useState([]);
  // To store Properties list for Create Booking Select Field
  const [properties, setProperties] = useState([]);
  // To show major errors
  const [error, setError] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  // To make API calls to protected endpoints
  const axios = useAxiosPrivate();
  // To open or close Create Form
  const [open, setOpen] = useState(false);
  // To Store the booking details before creating
  const [newBooking, setCreateBooking] = useState({
    property: "",
    start_date: "",
    end_date: "",
    checkin_time: "",
    checkout_time: "",
    deposit_amount: "0.00",
    num_guests:2
  });
  // To display errors on create form
  const [createFormError, setCreateFormError] = useState("");
  // Storing Ref of Error box on Create booking popup
  const errorRef = useRef(null);

  // For Fetching bookings Again after new property added
  // ------- Needs refector
  async function fetchData1() {
    try {
      const response = await axios.get(BOOKINGS_URL);
      setBookings(response.data);
      console.log("Data: ", response.data);
      setIsDataLoading(false);
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail){
        setError(error.response.data.detail);
      }
      else {
        setError("Failed to fetch Bookings. Please try again!");
      }
      setIsDataLoading(false);
    }
  }

  function resetNewBookingData() {
    setCreateBooking({
      property: "",
      start_date: "",
      end_date: "",
      checkin_time: "",
      checkout_time: "",
      deposit_amount: "0.00",
      num_guests:2
    });
  }

  async function fetchProperties() {
    // Fetch Properties for Create Booking
    try {
      const response = await axios.get(PROPERTIES_URL);
      setProperties(response.data);
    } catch (error) {
      if(error.response?.data?.detail){
        setCreateFormError(error.response.data.detail);
      }
      else {
        setCreateFormError("Failed to fetch Properties. Please try again!");
      }

    }
  }

  const handleCreateClick = () => {
    // To Open Create Form
    setOpen(true);
    fetchProperties();
  };

  const handleCreateClose = () => {
    // To Close Create Form
    setCreateFormError("");
    setOpen(false);
    resetNewBookingData();
  };

  async function addnewBooking() {
    // To create a new Property using "newBooking" state
    console.log("newBooking:", newBooking);
    try {
      const response = await axios.post(BOOKINGS_URL, newBooking);
      console.log(response.data);
      setIsDataLoading(true);
      // When Property added Successfully Fetch all bookings again.
      fetchData1();
    } catch (error) {
      console.error(error);
      if(error.response?.data?.detail){
        setError(error.response.data.detail);
      }
      else {
        setError("Failed to add new booking. Please try again!");
      }
      setIsDataLoading(false);
    }
  }

  const handleCreateSubmit = () => {
    // Check all fields are filled in

    if (!newBooking.start_date || !newBooking.end_date || !newBooking.property || !newBooking.checkin_time || !newBooking.checkout_time || !newBooking.deposit_amount || !newBooking.contact_number) {
      setCreateFormError("Please fill in all required fields.");
      // Scroll Top to the Create Form error
      errorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    setError("");
    setCreateFormError("");
    setIsDataLoading(true);
    // If everything is good then create new property
    addnewBooking();
    setOpen(false);
    resetNewBookingData();
  };
  const handleChange = (e) => {
    // set "newBooking" state on every change on form
    setCreateBooking({ ...newBooking, [e.target.name]: e.target.value });
  };

  function getProperty(id) {
    for (let i = 0; properties && i < properties.length; i++) {
      if (properties[i].id === id) return properties[i];
    }
    return {};
  }
  const handlePropertyChange = (e) => {
    // function to set default checkIn and Out time when a property is selected
    const updatedBooking = { ...newBooking }; // Creating copy of the newBooking object
    let property = getProperty(e.target.value);
    // Pre-populate Check In time from Property
    if (property.default_checkin_time) {
      updatedBooking.checkin_time = property.default_checkin_time;
    }
    // Pre-populate Check Out time from Property
    if (property.default_checkout_time) {
      updatedBooking.checkout_time = property.default_checkout_time;
    }
    // Pre-populate Deposit Amount from Property
    if (property.default_deposit_amount) {
      updatedBooking.deposit_amount = property.default_deposit_amount;
    }

    updatedBooking[e.target.name] = e.target.value;
    setCreateBooking(updatedBooking);
  };

  useEffect(() => {
    // Fetch bookings when the page loads
    async function fetchData() {
      try {
        const response = await axios.get(BOOKINGS_URL);
        setBookings(response.data);
        console.log(response.data);
        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        if(error.response?.data?.detail){
          setError(error.response.data.detail);
        }
        else {
          setError("Failed to fetch bookings. Please try again!");
        }        
        setIsDataLoading(false);
      }
    }

    
    fetchData();
  }, []);

  async function onChange() {
    setIsDataLoading(true);
    try {
      const response = await axios.get(BOOKINGS_URL);
      setBookings(response.data);
      console.log(response.data);
      setIsDataLoading(false);
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail){
        setError(error.response.data.detail);
      }
      else {
        setError("Failed to fetch bookings. Please try again!");
      }
      setIsDataLoading(false);
    }
  }
  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':');
    return new Date(1970, 0, 1, hours, minutes, seconds);
  };

  const upcomingBookings = useMemo(()=>bookings.filter((booking)=> booking.status=== "UPCOMING"),[bookings]).sort((a, b) => {
    // Compare end_date first
    const aDate = new Date(String(a.start_date));
    const bDate = new Date(String(b.start_date));
    if (aDate !== bDate) {
      return aDate - bDate;
    }
// If end_date is the same, compare checkout_time
    const aTime = parseTime(String(a.checkin_time));
    const bTime = parseTime(String(b.checkin_time));

    if (!aTime || !bTime) {
      // Handle cases where time parsing failed
      return 0; // Maintain current order if parsing failed
    }

    return aTime - bTime;
  });
  const bookingsInProgress = useMemo(()=>bookings.filter((booking)=> booking.status!== "UPCOMING"),[bookings]).sort((a, b) => {
    // Compare end_date first
    const aDate = new Date(String(a.end_date));
    const bDate = new Date(String(b.end_date));
    if (aDate !== bDate) {
      return aDate - bDate;
    }

    // If end_date is the same, compare checkout_time
    const aTime = parseTime(String(a.checkout_time));
    const bTime = parseTime(String(b.checkout_time));

    if (!aTime || !bTime) {
      // Handle cases where time parsing failed
      return 0; // Maintain current order if parsing failed
    }

    return aTime - bTime;
  });
  return (
    <>
      {isDataLoading ? (
        <Backdrop
          open={isDataLoading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Container maxWidth="xl" >
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
              <Link underline="hover" color="inherit" component={RouterLink} to={`/`}>
                Home
              </Link>
              <Typography color="text.primary">Bookings</Typography>
            </Breadcrumbs>
          </Container>
          <Container component="main" maxWidth="xl">
            <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "0.5rem" }}>
              <Grid item>
                <Typography variant="h5">Upcoming Bookings</Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <Button variant="outlined" color="success" size="large" onClick={handleCreateClick}>
                    Create
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            {/* Errors releted to data fetching */}
            {error && <CustomErrorBox errorMessage={error} />}
            {/* Display All Bookings using BookingsDataGrid component */}
            {
              upcomingBookings &&
              upcomingBookings.length > 0 &&
              <BookingsDataGrid 
                properties={upcomingBookings} 
                showProperties={true} 
                onBookingChange={onChange} />
            }
            {
              bookingsInProgress &&
              bookingsInProgress.length > 0 &&
              <>
                <Typography variant="h5" align="left" sx={{marginTop: "1.4rem", marginBottom: "1.1rem"}}>Bookings in Progress</Typography>
                <BookingsDataGrid
                  properties={bookingsInProgress}
                  showProperties={true}
                  onBookingChange={onChange} />
              </>
            }
            
          </Container>
          {/* Create Form  */}
          <Dialog open={open} onClose={handleCreateClose}>
            <DialogTitle>Create Booking</DialogTitle>
            <DialogContent>
              <DialogContentText>Enter the booking details:</DialogContentText>
              {createFormError && (
                <Typography color="error" ref={errorRef}>
                  {createFormError}
                </Typography>
              )}
              <InputLabel htmlFor="property-select">Select Property</InputLabel>
              <Select
                autoFocus
                id="property-select"
                margin="dense"
                name="property"
                label="Select Property"
                value={newBooking.property}
                onChange={handlePropertyChange}
                fullWidth
                required>
                {properties &&
                  properties.length > 0 &&
                  properties.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name}
                    </MenuItem>
                  ))}
              </Select>
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                name="start_date"
                value={newBooking.start_date}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                required
              />
              <TextField
                margin="dense"
                label="End Date"
                type="date"
                name="end_date"
                value={newBooking.end_date}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: newBooking.start_date,
                }}
                onChange={handleChange}
                required
              />

              <TextField
                margin="dense"
                label="CheckIn Time"
                type="time"
                fullWidth
                name="checkin_time"
                value={newBooking.checkin_time}
                onChange={handleChange}
                inputProps={{
                  step: 300, // 5 minutes step
                }}
                required
              />
              <TextField
                margin="dense"
                label="CheckOut Time"
                type="time"
                fullWidth
                name="checkout_time"
                value={newBooking.checkout_time}
                onChange={handleChange}
                inputProps={{
                  step: 300, // 5 minutes step
                }}
                required
              />
              <TextField
                margin="dense"
                label="Deposit Amount"
                type="number"
                fullWidth
                name="deposit_amount"
                value={newBooking.deposit_amount}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    
                    min: 0,
                    max: 999999999999.99,
                  },
                }}
              />
              <TextField
                margin="dense"
                label="Contact Number"
                type="text"
                fullWidth
                name="contact_number"
                value={newBooking.contact_number}
                onChange={handleChange}
              />
                 <TextField
                margin="dense"
                label="Number of Guests"
                type="text"
                fullWidth
                name="num_guests"
                value={newBooking.num_guests}
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>


              <Button onClick={handleCreateClose}>Cancel</Button>
              <Button onClick={handleCreateSubmit}>Save</Button>
            </DialogActions>
          </Dialog>

        </>
      )}

    </>
  );
};

export default Bookings;