import { createContext, useState } from "react";

const AuthContext = createContext({});
// Using UseContext hook to provide auth and role state of user
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState("");
  const [role, setRole] = useState("");

  return <AuthContext.Provider value={{ auth, setAuth, role, setRole }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
