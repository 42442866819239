import React, { useState } from "react";
import { Button, TextField, Typography, Grid, Box, Hidden, Container, CircularProgress, Backdrop } from "@mui/material";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { setLocalStorageTokens } from "../../utils/utilityFunctions";
import { setLocalStorageRoles } from "../../utils/utilityFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import CustomErrorBox from "components/ErrorAlert";
import { BASE_URL } from "config";

const Login = () => {
  const LOGIN_URL = BASE_URL + "/api-token-auth/";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setAuth } = useAuth();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsDataLoading(true);
    try {
      const response = await axios.post(LOGIN_URL, {
        username: username,
        password: password,
      });
      setAuth(response.data.token);
      setLocalStorageTokens(response.data.token);
      setLocalStorageRoles(response.data.role);
      console.log("Login Data: ", response.data.token);
      navigate(from, { replace: true });
      setIsDataLoading(false);
    } catch (error) {
      console.log(error);
      setError("Invalid credentials. Please try again.");
      setIsDataLoading(false);
    }
  };

  return (
    <Grid container style={{ height: "100vh", overflow: "hidden" }}>
      <Hidden smDown>
        <Grid
          item
          xs={7}
          style={{ backgroundColor: "rgb(116,170,251)" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            borderTopRightRadius: "2rem",
            borderBottomRightRadius: "2rem",
          }}>
          <Box component="img" src="/img/login3.jpg" alt="BellBoy" sx={{ width: "100%", height: "100%", objectFit: "cover" }} />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={5}>
        <Container maxWidth="sm" sx={{ height: "100%" }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" padding={{ xs: "1rem", sm: "2rem", md: "3rem" }}>
            <Typography variant="h1" color="rgb(116,170,251)" sx={{ fontWeight: "bold", marginBottom: "2.5rem" }}>
              Login
            </Typography>
            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <TextField label="Username" variant="outlined" margin="normal" fullWidth value={username} onChange={(e) => setUsername(e.target.value)} />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <CustomErrorBox errorMessage={error} />}
              <Button variant="contained" fullWidth type="submit" sx={{ marginTop: "1.8rem", padding: 1.5, backgroundColor: "#74AAFB" }}>
                Login
              </Button>
            </form>
          </Box>
          <Backdrop
            open={isDataLoading}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
