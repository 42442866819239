import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
    Container,
    Button,
    Typography,
    Backdrop, CircularProgress
} from "@mui/material";
import AnalyticEcommerce from "components/cards/statistics/AnalyticEcommerce";
import React, { useState } from "react";
import { ApiService } from "api/axios";
import CustomSnackbar from "components/Snackbar";

const CheckoutForm = ({uuid, deposit_amount, handleCustomerExistState}) => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    // Handle real-time validation errors from the CardElement.
    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    };
    // Handle form submission.
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Disable form submission until Stripe.js has loaded.
            setIsLoading(false);
            setIsSubmitting(false);
            return;
        }
        const card = elements.getElement(CardElement);
        // Create Payment Method
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
        });
        console.log("Payment :", paymentMethod);
        if (error) {
            if(error.message)
            {
                setError(error.message);
            }
            else {
                setError("Invalid payment credentials!")
            }
            setIsLoading(false);
            setIsSubmitting(false);
            console.log("Error: ", error);
            return;
        }
        // Create Payment
        ApiService.saveStripeInfo({

            payment_method_id: paymentMethod.id,
        },uuid)
            .then((response) => {
                console.log(response.data);
                if (response.data.status === "PAID") {
                    setSuccessMessage("Payment Successful");
                    // When Payment Successfull
                    // Move the customer to next Step, i.e, Booking Details
                    handleCustomerExistState(2);
                } else {
                    setError("Payment Unsuccessful due to some unknown reason!");
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response?.data?.detail)
                {
                    setError(error.response.data.detail);
                }
                else {
                    setError("Payment Unsuccessful due to some unknown reason!")
                }
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitting(false);
            });
    };
    return (
        <Container maxWidth="sm">
            <Backdrop
                open={isLoading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomSnackbar message={successMessage} closeSnackbar={()=> setSuccessMessage("")} severity="success" />
            <CustomSnackbar message={error} closeSnackbar={()=> setError("")} severity="error" />
            <Typography variant="h4">Payment Details</Typography>
            <div style={{ margin: "30px 0"}}>
                <AnalyticEcommerce title="Deposit Amount" count={deposit_amount} />
            </div>
            <form onSubmit={handleSubmit} className="stripe-form">

                <div style={{ marginBottom: "20px" }}>
                    <CardElement
                        id="card-element"
                        label="Credit or Debit Card"
                        onChange={handleChange}
                        options={{
                            style: {
                                base: {
                                    fontSize: "16px",
                                    color: "#424770",
                                    "::placeholder": {
                                        color: "#aab7c4",
                                    },
                                },
                                invalid: {
                                    color: "#9e2146",
                                },
                            },
                        }}
                    />
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="submit-btn"
                    disabled={!stripe || isSubmitting}
                >
                    {isSubmitting ? "Submitting..." : "Submit Payment"}
                </Button>
            </form>
        </Container>
    );
};
export default CheckoutForm;
