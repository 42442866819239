import React, { useState, useEffect  } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Container, TextField, Button, Typography, Backdrop, CircularProgress, Box, Grid, Paper, FormControl,Tooltip } from "@mui/material";
import { useParams, useNavigate ,Link} from "react-router-dom";
import { ApiService } from "api/axios";
import CustomSnackbar from "components/Snackbar";
import CustomButton from "./CustomButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import StyledList from "./StyledList";
import GuestHeader from "./GuestHeader";
import OuterContainer from "./OuterContainer";
import TextSection from "./TextSection";
import { BASE_URL } from "config";
import axios from "api/axios";

const PayDeposit = () => {
  const [depositAmount, setDepositAmount] = useState('0');
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    cardName: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
  });

  const stripe = useStripe();
  const elements = useElements();
  const { uuid } = useParams();
  const navigate = useNavigate();


  const depositePaymentText = [
    'To secure your reservation, the deposit is required.',
    'This deposit will be used to cover any potential incidentals or damages incurred during your stay.',
    'Please note that you will not be able to access the property or "check in" until the deposit is settled.',
    'The full deposit amount will be returned to you after a successful check-out, provided there are no outstanding charges for incidentals or damages.',
    'We recommend inspecting the room upon arrival and reporting any pre-existing damages to the reception staff immediately.'
  ];

  useEffect(() => {
    const fetch_deposit = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bookings/${uuid}`);
        console.log(response.data.deposit_amount)
        setDepositAmount(response.data.deposit_amount);
      } catch (error) {
        console.error('Error fetching check-in time:', error);
      }
    };
    fetch_deposit();
  }, [uuid]);
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      setIsSubmitting(false);
      return;
    }
    const card = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });
    console.log(paymentMethod, 'card', error)
    if (error) {
      setError(error.message || "Invalid payment credentials!");
      setIsLoading(false);
      setIsSubmitting(false);
      return;
    }

    ApiService.saveStripeInfo({
      payment_method_id: paymentMethod.id,
    }, uuid)
      .then((response) => {
        //  axios.post(`${BASE_URL}/payments/${uuid}`, {payment_method_id: response.data.id})
        if (response.data.status === "PAID") {
          setSuccessMessage("Payment Successful");
          navigate(`/bookings/${uuid}/precheckin`);
        } else {
          setError("Payment Unsuccessful due to some unknown reason!");
        }
      })
      .catch((error) => {
        setError(error.response?.data?.detail || "Payment Unsuccessful due to some unknown reason!");
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitting(false);
      });
  };

  return (
    <OuterContainer>
      <GuestHeader />
      <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomSnackbar message={successMessage} closeSnackbar={() => setSuccessMessage("")} severity="success" />
      <CustomSnackbar message={error} closeSnackbar={() => setError("")} severity="error" />
      <TextSection>
        <Box>
          <Typography variant="h2">Pay Deposit</Typography>
        </Box>
        <Grid container spacing={2} sx={{ color: '#3D3D3D' }} rowSpacing={1} columnSpacing={{ md: 10 }}>
          <Grid item xs={12} md={4} >
            <Paper sx={{ p: 1, mt: 4 }}>
              <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: '500' }}> Deposit Amount</Typography>
                <Typography variant="h5"> {depositAmount}AED</Typography>
              </Box>
            </Paper>
            <Paper sx={{ p: 4, mt: 6 }}>
              <Box>
                {depositAmount > 0.00 ?
                  <>
                    <Box sx={{ mb: 1 }}>
                      <Typography variant="h4">Card Details</Typography>
                    </Box>
                    <Box>
                      <CardElement onChange={handleChange} />
                    </Box>
                    <FormControl>
                      <Grid item xs={12}></Grid>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                      <label>Card Name</label>
                      <TextField
                        fullWidth
                        name="cardName"
                        value={formData.cardName}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid> */}
                        {/* <Grid item xs={6}>
                      <label>Expiry Month</label>
                      <TextField
                        fullWidth
                        name="expiryMonth"
                        value={formData.expiryMonth}
                        onChange={handleInputChange}
                      />
                    </Grid> */}
                        {/* <Grid item xs={6}>
                      <label>Expiry Year</label>
                      <TextField
                        fullWidth
                        name="expiryYear"
                        value={formData.expiryYear}
                        onChange={handleInputChange}
                      />
                    </Grid> */}
                        {/* <Grid item xs={6}>
                      <label>CVV</label>
                      <TextField
                        fullWidth
                        name="cvv"
                        type="password"
                        value={formData.cvv}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid> */}
                        {/* <Grid item xs={12}>
                      <CardElement onChange={handleChange} />
                    </Grid> */}
                      </Grid>
                      <Grid container justifyContent='center'>
                        <Grid item xs={4} sx={{ mt: 5 }} >
                          <CustomButton onClick={handleSubmit} text={'Pay'} />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </> :
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="h4">No payment is required at this point!</Typography>
                  </Box>
                }

              </Box>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
              <Link to={`/bookings/${uuid}/precheckin`} style={{ textDecoration: 'none', color: '#004AAD' }}>
                Skip for now
              </Link>
              <KeyboardArrowRightIcon sx={{ color: '#004AAD' }} />
            </Box>

          </Grid>
          <Grid item xs={12} md={7} sx={{ mt: 4 }}>
            <StyledList title="Deposit Payment" items={depositePaymentText} />
          </Grid>

        </Grid>


        
        <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <Tooltip title="Chat with us" arrow>
          <Button
            variant="contained"
            color="primary"
            sx={{
              minWidth: "auto",
              width: 56,
              height: 56,
              borderRadius: "50%",
            }}
          >
            <ChatBubbleOutlineIcon />
          </Button>
        </Tooltip>
      </Box>

      </TextSection>
    </OuterContainer>
  );
}

export default PayDeposit;
