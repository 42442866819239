import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TextHeading from './GuestComponent/TextHeading';

const PropertyDetails = ({ propertydetails }) => {
  const [showMore, setShowMore] = useState(false);

  // Truncate the property details by splitting them by line breaks and limiting the number of lines
  const detailsList = propertydetails.split('\n').filter(line => line.trim() !== '');

  const getTruncatedText = (textArray, lineLimit) => {
    if (!textArray) {
      return '';
    }
    if (textArray.length > lineLimit) {
      return textArray.slice(0, lineLimit).join('\n') + '...';
    }
    return textArray.join('\n');
  };

  const truncatedText = getTruncatedText(detailsList, 10); // Display the first 10 lines initially

  return (
    <Box
      sx={{
        width: { xs: '370', sm: 'auto', md: 'auto', lg: 'auto' },
        height: { xs: '130', sm: 'auto' },
        overflow: 'hidden',
      }}
    >
      <TextHeading text="Property Details" />
      <Typography 
      
        variant="body2" 
        sx={{
          // changes made
          fontSize: {xs:'12px', md:'14px'},
          fontWeight: '400',
          padding: 0,
            margin: 0,
          '& ul, & ol, & p': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
          },
        }}
        dangerouslySetInnerHTML={{ __html: showMore ? propertydetails : truncatedText }}
      />

      <Box
        onClick={() => setShowMore(!showMore)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#004AAD',
          fontSize: '13px',
          fontWeight: 'bold',
        }}
      >
        <Link component="button" sx={{ color: '#004AAD', fontSize: '12.5px', fontWeight: 'bold' }}>
          {showMore ? 'Show Less' : 'Know More'}
        </Link>
        <ArrowForwardIosSharpIcon
          fontSize="small"
          sx={{
            ml: 0.5,
            color: '#004AAD',
            transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
            fontSize: '12px',
          }}
        />
      </Box>
    </Box>
  );
};

export default PropertyDetails;
