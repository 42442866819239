import React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyIcon from '@mui/icons-material/Key';
import CameraIcon from '@mui/icons-material/Camera';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const getIcons = (iconNumber=1) => {
    {/* Function to return the MUI icon based on number */}
    switch (iconNumber) {
        case 1:
            return <AccessTimeIcon />;
        case 2:
            return <TodayIcon />;
        case 3:
            return <MapsHomeWorkIcon />;
        case 4:
            return <AttachMoneyIcon />;
        case 5:
            return <KeyIcon />;
        case 6:
            return <CameraIcon />;
        case 7:
            return <AccessTimeFilledIcon />;
        case 8:
            return <EventIcon />;
        case 9:
            return <CreditCardIcon />;
        case 10:
            return <LocationOnIcon />;

        default:
            return null;
    }
}

const ItemCard = ({ title, description, iconNumber, contentRightMargin="1rem", firstCard=0,secondCard=0,secondLastCard=0, lastCard=0, singleCard=0, link }) => {
  {/* Card with title, decription and icon */}
  return (
    // Card with adjustable border corners
    <Card sx={{
        display: 'flex',
        flexDirection: 'col',
        borderRadius: 0,
        height:"100%",
        borderTopLeftRadius: firstCard || singleCard? '5px': 0,
        borderTopRightRadius: secondCard || singleCard? '5px':0,
        borderBottomLeftRadius: secondLastCard || singleCard? '5px':0,
        borderBottomRightRadius: lastCard || singleCard? '5px':0,
        
        }}>
      {/* Left side with icon */}
      <CardMedia
        component="div"
        sx={{
          width: '10%',
          marginLeft: "1.8rem",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Display icon based on number */}
        { getIcons(iconNumber) }
      </CardMedia>
      {/* Right side with title and description */}
      <CardContent sx={{ flexGrow: 1, marginRight: contentRightMargin }}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        { link? 
            // When description is a link to some source like Address
            <Link component={RouterLink} to={link} target="_blank"> {description}</Link>
            :
            <Typography variant="body1" color="text.secondary">
              {/* Else simply show description */}
                {description}
            </Typography>
        }
        
      </CardContent>
    </Card>
  );
};

export default ItemCard;
