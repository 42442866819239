import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomerDetails from "./CustomerDetails";
import CustomerBooking from "./CustomerBooking";
import axios from "api/axios";
import CustomizedSteppers from "./CustomerStepper";
import { Container, Typography, Backdrop, CircularProgress } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "pages/customer-client/CheckoutForm";
import CustomerFeedback from "./CustomerFeedback";

const stripePromise = loadStripe("pk_test_51OHNSwDTiGYmJ4THjxCNZj2ddTFPWL1FWKLo1LCAanyFrX60o6k82zZvINekODRdigGwFDtJhQtKwL73N6zvRvNC00p1ocEp0h");

const Customer = () => {
  // Get UUID from Path Params
  const { uuid } = useParams();
  // State to decide which step of page to be shown to customer
  const [customerExists, setCustomerExists] = useState(0);
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCustomerExistState = (isCustomer) => {
    // To change or move the customer to next step
    setCustomerExists(isCustomer);
  };

  useEffect(() => {
    async function checkBookingCustomer() {
      // Function to decide which step of booking customer is on
      try {
        const response = await axios.get(`/bookings/${uuid}`);
        console.log("CU", response.data);
        setBooking(response.data);
        setIsLoading(false);
        if (response.data.status === "CANCELLED"){
          setCustomerExists(5);
        }
        else if (response.data.payment_details && response.data.payment_details.status === "REFUNDED") {
          // When Payment is Refunded, the Booking ended so, 4
          setCustomerExists(4);
        } else if (response.data.customer) {
          // If the Customer is attached then Step 0 is clear
          if (response.data.payment_details && (response.data.payment_details.status === "PAID" || response.data.payment_details.status === "NOT_APPLICABLE")) {
            // If the payment is attached then Step 1 is clear
            if (response.data.status === "AWAITING_CLEANING" || response.data.status === "COMPLETED") {
              // If the Booking is checked Out Step 2 is clear
              if (response.data.feedback_submitted) {
                // If the feedback is submitted, Step 3 is Clear
                // Set to Step 4 as booking is completed on customer end
                setCustomerExists(4);
              } else {
                // Set to Step 3 as feedback is not submmitted
                setCustomerExists(3);
              }
            } else {
              // Set to Step 2, as Booking is Active
              setCustomerExists(2);
            }
          } else {
            // Set to Step 1, as Payment is not submitted
            setCustomerExists(1);
          }
        }
        // When Customer is not attached, the default page is, 0
      } catch (e) {
        console.log(e);
        setErrorMessage("Failed to get booking details. Please try again!")
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    checkBookingCustomer();
  }, [uuid]);

  return (
    <Container component="main">
      {isLoading ? (
        <Backdrop
          open={isLoading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ): (
        errorMessage? (
          <Typography color="error">{errorMessage}</Typography>
        ): booking && (
          <div>
            {/* Stepper Based on Customer Step */}
            <CustomizedSteppers activeStepNo={customerExists} />
            {/* Show Customer name when Customer Attached */}
            {customerExists > 0 && booking?.customer && booking.customer_details && (
              <div style={{ margin: "20px 0" }}>
                <Typography variant="h2" component="span" sx={{ fontWeight: 900 }}>
                  Hi,{" "}
                </Typography>
                <Typography variant="h2" component="span" sx={{ fontWeight: 900  }}>
                  {booking.customer_details.customer_name}
                </Typography>
              </div>
            )}
            {customerExists === 0 ? (
              // Enter Details page
              <>
                <CustomerDetails uuid={uuid} payment_status={booking.payment_details.status} handleCustomerExistState={handleCustomerExistState} />
              </>
            ) : customerExists === 1 ? (
              <>
                {/* Payment Page */}
                {booking && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm uuid={uuid} deposit_amount={booking.deposit_amount} handleCustomerExistState={handleCustomerExistState} />
                  </Elements>
                )}
              </>
            ) : customerExists === 2 ? (
              // Booking details page
              <>
                <CustomerBooking uuid={uuid} handleCustomerExistState={handleCustomerExistState} />
              </>
            ) : customerExists === 3 ? (
              // Feedback Page
              <>
                <CustomerFeedback uuid={uuid} handleCustomerExistState={handleCustomerExistState} />
              </>
            ) : customerExists === 5 ? (
              <Typography variant="h4">Booking Cancelled</Typography>
            ) : (
              // Booking Completed Page
              <>"Booking Completed. Thank You!"</>
            )}
          </div>
        )
      )}
      
    </Container>
  )
};

export default Customer;
