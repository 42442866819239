import React from 'react';
import { Typography, Container } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const TextSection = ({ title, description, footerText, children }) => {
  return (
    // changes made here
    <Container maxWidth=" lg: '1200px',xl: '1600px'" sx={{ mt: 4, mb: 4, flexGrow: 1, pl:{md:10, lg:10} ,pr:{md:10, lg:10} }}>
      {(title &&
        <Typography
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            // changes made here
            fontSize:{xs:'20px', md:"32px"}
          }}
        >
          {title}
        </Typography>
      )
      }

      {
        (description && <Typography
         
          gutterBottom
          sx={{ display: "flex", alignItems: "center", fontSize:{xs:'12px', md:"14px"} }}
        >
          {description}
        </Typography>)

      }
      {
        (footerText && <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#3D3D3D",
          }}
        >
          {footerText ? <LockIcon fontSize="small" sx={{ mr: 1, color: '#004AAD' }} /> : ' '}
          {footerText}
        </Typography>)
      }

      {children}
    </Container>
  );
};

export default TextSection;
