import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = ({message,closeSnackbar, severity="error"}) => {
    // Custom snack bar to show Error, Success and other messages
    return ( message &&
        <Snackbar open={message !== ""} autoHideDuration={4000} onClose={closeSnackbar}>
          { message &&          
            <Alert onClose={closeSnackbar} severity={severity} sx={{ width: "100%" }}>
              {message}
            </Alert>
          }
        </Snackbar>
    )

}

export default CustomSnackbar;