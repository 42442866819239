import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import BuildIcon from '@mui/icons-material/Build';
import HandymanIcon from '@mui/icons-material/Handyman';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import axios from 'api/axios';
import TextHeading from './GuestComponent/TextHeading';

const Services = ({ bookingDetails, uuid }) => {
  const [services, setServices] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [thankYouMessage, setThankYouMessage] = useState(false);

  const id = bookingDetails?.property_details.property_id;
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    const fetchServices = async () => {
      try {
        if (id) {
          const response = await axios.get(`/properties/${id}/services`);
          function getServicesIcon(icon) {
            const serviceIcons = {
              'Airport Pick Up': <AirportShuttleIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />,
              'Early Check In': <AlarmOnIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />,
              'Late Check Out': <AlarmOnIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />,
              'Maintenance': <BuildIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />,
              'Housekeeping': <CleaningServicesIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />,
            };
            return serviceIcons[icon] || <HandymanIcon sx={{ width: 23.61, height: 25.18, color: '#004AAD' }} />;
          }
          const fetchedServices = response.data.map((service) => ({
            name: service.service_name,
            icon: getServicesIcon(service.service_name),
            type: service.is_pre_service ? 'pre' : 'post',
          }));

          setServices(fetchedServices);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
        setIsDisabled(true);
      }
    };
    fetchServices();
  }, [id ,bookingDetails]);
  
  const filteredServices = bookingDetails?.is_checked_in
    ? services
    : services.filter((service) => service.type === 'pre');

    console.log(filteredServices,'filteredServices')

  const maxVisibleServices = isLargeScreen ? 5 : 4;
  const displayedServices = showMore ? filteredServices : filteredServices.slice(0, maxVisibleServices);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setThankYouMessage(false);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedService(null);
    setThankYouMessage(false);
  };

  const handleThanksClose=()=>{
    setOpenModal(false);
  }

  const handleContinue = async () => {
    try {
      const response = await axios.post(`https://api.airtable.com/v0/app5TdkPksXfOTyZh/tblYTl1AOJav62bX4`, {
        "records": [
            {
                "fields": {
                    "bookingId": `${uuid}`,
                    "serviceName": `${selectedService?.name}`,
                    "domain": window.location.host,
                    "guestName": `${bookingDetails.guests[0].full_name}`,
                    "guestNumber":`+${bookingDetails.guests[0].dial_code}${bookingDetails.guests[0].phone_number}`,
                    "propertyName":`${bookingDetails.property_details.property_name}`
                }
            }
        ]
    },{
        headers: {
          'Authorization': `Bearer patC74MPNvgHgRIFL.0d33951a94138424bca8083346ec71baf160efd0e8d086a059c45c43a0a878b0`,
          
        }
      });
      if(response.status === 200){
        setThankYouMessage(true);
      }
      
    } catch (error) {
      console.error('Error requesting service:', error);
      handleClose();
    }
  };

  return (
    <Box sx={{ paddingBottom: services.length <= maxVisibleServices ? '20px' : '0px' }}>
      <TextHeading text="Services" />
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        {displayedServices.map((service, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={4}
            md={3}
            lg={2.4}
          >
            <Box
              onClick={() => handleServiceClick(service)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #ccc',
                borderRadius: '6px',
                backgroundColor: '#FCFCFC',
                color: '#000',
                padding: '10px 7px 10px 7px',
                cursor: 'pointer',
              }}
            >
              <Box sx={{ marginRight: '10px', mt: 1 }}>{service.icon}</Box>
              <Typography variant="body2" sx={{ fontSize: { xs: '12px', md: '14px' }, color: '#3D3D3D' }}>
                {service.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {filteredServices.length > maxVisibleServices && (
        <Box
          onClick={() => setShowMore(!showMore)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#004AAD',
            marginTop: '7px',
            cursor: 'pointer',
          }}
        >
          <Link component="button" sx={{ color: '#004AAD', fontSize: '13px', fontWeight: 'bold' }}>
            {showMore ? 'Show Less' : 'Show More'}
          </Link>
          <ArrowForwardIosSharpIcon
            fontSize="small"
            sx={{
              ml: 0.5,
              color: '#004AAD',
              transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
              fontSize: '13px',
            }}
          />
        </Box>
      )}

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="service-dialog-title"
       
      >
        <DialogTitle id="service-dialog-title">
          <Typography variant='h4'>{thankYouMessage ? "" : `Confirm ${selectedService?.name} Service?`}</Typography>
        </DialogTitle>
        <DialogContent>
          {thankYouMessage ? (
            <Typography variant="body1">
              Thank you for taking the services, we will get back to you.
            </Typography>
          ) : (
            <Typography variant="body1">
              We would like to schedule {selectedService?.name} during your stay. We'll reach out shortly to confirm the details and ensure your room is tidy.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {thankYouMessage ? (
            <Button onClick={handleThanksClose} variant="contained" sx={{ backgroundColor: '#004AAD' }}>Close</Button>
          ) : (
            <>
              <Button onClick={handleClose} variant="outlined">Cancel</Button>
              <Button onClick={handleContinue} variant="contained" sx={{ backgroundColor: '#004AAD' }}>Continue</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Services;
