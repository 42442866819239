import React, { useState, useEffect } from 'react';
import axios from 'api/axios';
import { MenuItem, Typography, TextField, Box, Tooltip, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "config";
import { useParams } from 'react-router-dom';
import GuestHeader from './GuestHeader';
import OuterContainer from "./OuterContainer";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import TextSection from "./TextSection";
import CustomButton from './CustomButton';
import Loader from './Loader';
function ArrivalTime() {
    const navigate = useNavigate();
    const { uuid } = useParams();

    const [time, setTime] = useState('');
    const [checkInTime, setCheckInTime] = useState('');
    const [timeOptions, setTimeOptions] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading

    useEffect(() => {
        const fetchCheckInTime = async () => {
            try {
                const bookingResponse = await axios.get(`${BASE_URL}/bookings/${uuid}`);
                const arrivalTime = bookingResponse.data.arrival_time || '';  // Get arrival_time or set to empty string
                // Second API Call to get the check-in time
                const checkInResponse = await axios.get(`${BASE_URL}/bookings/${uuid}/get_checkin_time`);
                const checkInTime = checkInResponse.data;
                setCheckInTime(checkInTime);
                const formattedCheckInTime = formatTimeTo12Hour(checkInTime);
                const formattedArrivalTime = arrivalTime ? formatTimeTo12Hour(arrivalTime) : null;
                setTimeOptions(generateTimeOptions(formattedCheckInTime));
                setTime(formattedArrivalTime || formattedCheckInTime);
            } catch (error) {
                console.error('Error fetching check-in time:', error);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };
        fetchCheckInTime();
    }, [uuid]);

    const formatTimeTo12Hour = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM/PM
        return `${String(formattedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
    };

    const generateTimeOptions = (startTime) => {
        const times = [];
        const [startHour, startMinute, period] = startTime.split(/:| /);
        const startHour24 = period === 'PM' && startHour < 12 ? parseInt(startHour) + 12 : (period === 'AM' && startHour === '12' ? 0 : parseInt(startHour));
        const startMinute24 = parseInt(startMinute);

        for (let hour = startHour24; hour < 24; hour++) {
            for (let minute = (hour === startHour24 ? startMinute24 : 0); minute < 60; minute += 30) {
                const formattedTime = formatTimeTo12Hour(`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`);
                times.push(formattedTime);
            }
        }

        return times;
    };

    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const timeIn24HourFormat = time.includes('PM') ?
                (time.startsWith('12') ? '12' : (parseInt(time.split(':')[0]) + 12).toString().padStart(2, '0')) + ':' + time.split(':')[1].split(' ')[0] :
                (time.startsWith('12') ? '00' : time.split(':')[0].padStart(2, '0')) + ':' + time.split(':')[1].split(' ')[0];
            await axios.patch(`${BASE_URL}/bookings/${uuid}/arrival_time`, { arrival_time: timeIn24HourFormat });
            navigate(`/bookings/${uuid}/precheckin`);
        } catch (error) {
            console.error('Error confirming arrival time:', error);
            alert('Failed to confirm arrival time.');
        }
    };

    const menuProps = {
        PaperProps: {
            sx: {
                backgroundColor: '#fff',
                color: '#000',
            }
        }
    };

    return (
        <OuterContainer>
            <GuestHeader link={`/bookings/${uuid}/review-booking`} />
            <TextSection
                title="Confirm Your Arrival Time"
                description="Provide your estimated arrival time to the property. Your arrival time helps us to schedule cleaning times and arrange for a better stay."
           
            >
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                display: "flex", alignItems: "center",
                                color: "#3D3D3D", fontSize:{xs:'16px', md:"20px"}
                            }}
                        >
                            Your Check-In Time: {formatTimeTo12Hour(checkInTime)}
                        </Typography>

                        <TextField
                            select
                            value={time}
                            onChange={handleTimeChange}
                            variant="outlined"
                            sx={{
                                width: '300px',
                                backgroundColor: '#fff',
                                '@media (max-width: 600px)': {
                                    width: '100%',
                                }
                            }}
                            SelectProps={menuProps}
                        >
                            {timeOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* changes made here */}
                        <Box sx={{ mt: 4, display: "flex", justifyContent: {xs:'center', md:'start'} }}>
                        <CustomButton text="Submit" onClick={handleSubmit} />
                        </Box>
                        
                    </>
                )}

                <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
                    <Tooltip title="Chat with us" arrow>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                minWidth: "auto",
                                width: 56,
                                height: 56,
                                borderRadius: "50%",
                            }}
                        >
                            <ChatBubbleOutlineIcon />
                        </Button>
                    </Tooltip>
                </Box>
            </TextSection>
        </OuterContainer>
    );
}

export default ArrivalTime;
