import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import listIconImg from "../../images/listIcon.svg";
import BookingDetailsCheckIn from "./BookingDetails";
import Loader from "./Loader"; // Import the Loader component
import { useNavigate, useLocation ,useParams } from "react-router-dom";

const WelcomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const { uuid } = useParams();
  // Loading state
  const [loading, setLoading] = useState(true);

  // Simulate a loading delay or fetch data
  useEffect(() => {
    const loadData = async () => {
      // Simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };

    loadData();
  }, []);

  const handleGetStarted = () => {
    navigate(`/bookings/${uuid}/document-upload`);
  };

  // if (loading) {
  //   return <Loader />; // Show the loader while loading
  // }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          flex: 1,
          backgroundColor: "#004AAD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "9% 8% 9%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h1"
            align="center"
            color="white"
            sx={{ mt: 3, mb: 3, width: '164px', height: '38px' }}
          >
            Welcome!
          </Typography>
          <Box sx={{ mt: 3, mb: 3}}>
            {/* <img src={"/img/logo.svg"} alt="Logo" style={{ height: "40px" }} /> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{m: "-6% 4.9% 0%",}}><BookingDetailsCheckIn hideExtraDetails={false} /></Box>
      <Box sx={{ textAlign: "center", width:'90%', ml: {xs:'6vw', md:'5vw'}, mt:{xs:2, md:7, sm:5}, }}>
        <Box sx={{  textAlign: "left"}}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "500",  fontSize: {xs:'16px', md:'20px'} }}
          >
            Get ready for your upcoming stay!
          </Typography>
        </Box>
        <List sx={{ ml:-2, mt:-2, '& .MuiTypography-root': { fontSize: {xs:'12px', md:'16px'} }}}>
          <ListItem>
            <ListItemIcon>
              <img src={listIconImg} alt="listIcon" />
            </ListItemIcon>
            <ListItemText  primary="Upload Your Documents"  />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={listIconImg} alt="listIcon" />
            </ListItemIcon>
            <ListItemText primary="Review and Edit Your Details"  />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={listIconImg} alt="listIcon" />
            </ListItemIcon>
            <ListItemText primary="Select Your Arrival Time" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={listIconImg} alt="listIcon" />
            </ListItemIcon>
            <ListItemText primary="Pay Deposit" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={listIconImg} alt="listIcon" />
            </ListItemIcon>
            <ListItemText primary="Confirm and Relax!" />
          </ListItem>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2, mb: 3, display: "flex", justifyContent: "start", ml: "16px" }}
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </List>
      </Box>
      <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <Tooltip title="Chat with us" arrow>
          <Button
            variant="contained"
            color="primary"
            sx={{ minWidth: "auto", width: 56, height: 56, borderRadius: "50%" }}
          >
            <ChatBubbleOutlineIcon />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default WelcomePage;
