// Layout.js
import React from 'react';
import { Box } from '@mui/material';

const OuterContainer = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: '#f5f5f5',
      }}
    >
      {children}
    </Box>
  );
};

export default OuterContainer;
