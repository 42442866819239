import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Container,
  Stack,
  Link,
  Breadcrumbs,

} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink } from "react-router-dom";
import CustomSnackbar from "components/Snackbar";
import CustomErrorBox from "components/ErrorAlert";
import ItemCard from "components/cards/statistics/ItemCard";
import ConfirmationModal from "components/ConfirmationModal";

const Booking = () => {
  // booking id from path parameters
  const { id } = useParams();
  const BOOKING_URL = `/bookings`;
  // To store booking details to be Displayed
  const [booking, setBooking] = useState(null);
  // To display major errors
  const [error, setError] = useState("");
  // Show Loading when data is being fetched
  const [isDataLoading, setIsDataLoading] = useState(true);
  // To make calls to protected end points
  const axios = useAxiosPrivate();
  // To store booking details for Edit booking
  const [editBooking, setEditBooking] = useState({});
  // To open or close Edit booking popup
  const [open, setOpen] = useState(false);
  // To display errors on edit form
  const [editFormError, setEditFormError] = useState("");
  // To store Refund amount 
  const [amount, setAmount] = useState('');
  // To open or close Refund booking popup
  const [showRefundForm, setShowRefundForm] = useState(false);
  // To store Entry Key
  const [entryKey, setEntryKey] = useState("");

  const [documents, setDocuments] = useState([]);
  // To open or close Entry key booking popup
  const [showEntryKeyForm, setShowEntryKeyForm]= useState(false);
  // To display Error message in Snackbar
  const [errorMessage, setErrorMessage] = useState("");
  // To display Success message in Snackbar
  const [successMessage, setSuccessMessage] = useState("");
  // Storing Ref of Error box on Edit booking popup 
  const errorRef = useRef(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const handleEditClick = (booking) => {
    // When Edit button is clicked show Edit Form
    setEditBooking(booking);
    console.log("Select:",booking);
    setOpen(true);
  };

  const handleEditClose = () => {
    // Close Edit Form
    setOpen(false);
  };
  async function updateBooking() {
    // Update booking details after some change
    try {
      const response = await axios.put(BOOKING_URL + `/${booking.id}/`, editBooking);
      console.log(response.data);

      setBooking(editBooking);
      setIsDataLoading(false);
    } catch (error) {
      console.error(error);
      if(error.response?.data?.detail){
        setError(error.response?.data?.detail);
      } else {
        setError("Failed to edit the booking. Please try again!");
      }

      
      setIsDataLoading(false);
    }
  }
  const handleEditSubmit = () => {
    // When Edit booking Form button is clicked
    if (!editBooking.checkin_time || !editBooking.checkout_time || !editBooking.start_date || !editBooking.end_date || !editBooking.property) {
      // Check All the required field are filled
      setEditFormError("Please fill in all required fields.");
      // Scroll Top to the Error box in Edit form
      errorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    setError("");
    setEditFormError("");
    setIsDataLoading(true);

    updateBooking();
    setOpen(false);
  };

  const handleChange = (e) => {
    // Handle Change of Input on Edit form
    setEditBooking({ ...editBooking, [e.target.name]: e.target.value });
  };
  async function fetchBookingAgain() {
    // Fetch Booking data again from API after some Change like Refund, Entry Key,...
    try {
      const response = await axios.get(BOOKING_URL + `/${id}`);
      setBooking(response.data);
      setIsDataLoading(false);
      
    } catch (error) {
      console.error(error);
      if(error.response?.data?.detail){
        setError(error.response?.data?.detail);
      } else {
        setError("Failed to fetch booking details. Please try again!");
      }
      setIsDataLoading(false);
    }
  }
  
  useEffect(() => {
    // Fetch booking data from API on page load
    async function fetchData() {
      try {
        const response = await axios.get(BOOKING_URL + `/${id}`);
        setBooking(response.data);
        setIsDataLoading(false);
        
      } catch (error) {
        console.error(error);
        if(error.response?.data?.detail){
          setError(error.response?.data?.detail);
        } else {
          setError("Failed to fetch booking details. Please try again!");
        }
        setIsDataLoading(false);
      }
    }
    fetchData();
  }, [id, BOOKING_URL, axios]);


  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`/bookings/${id}/documents`);
        console.log("documents", response.data)
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [id, BOOKING_URL]);


  const handleRefundButtonClick = async () => {
    // When Refund Form Button is clicked
    setShowRefundForm(false);
    if (booking.payment_details?.paid && booking.deposit_amount >= parseFloat(booking.payment_details.refunded) + parseFloat(amount)) {
      // Check payment made and refund possible
      try {
        const response = await axios.post(`/payments/${booking.id}/refund`,{"deposit_amount": amount})
        console.log("Refund Success: ",response.data);
        setSuccessMessage("Refund Successful");
        setIsDataLoading(true);
        setAmount("");
        setErrorMessage("");
        fetchBookingAgain();
      }
      catch (e) {
        if(e.response?.data?.detail) {
          setErrorMessage(e.response.data.detail)
        }
        else {
          setErrorMessage("Refund Failed!")
        }
      }
    }
    else {
      setErrorMessage("Refund Not Possible!");
    }
  };

  const handleCancelRefundButtonClick = () => {
    // When Cancel Refund Form button is clicked close Refund form
    setShowRefundForm(false);
    setAmount('');
  };

  const handleRefundInputChange = (event) => {
    // Handle Input Change of refund form
    setAmount(event.target.value);
  };
  const handleSuccessSnackbarClose = ()=> {
    setSuccessMessage("");
  }
  const handleErrorSnackbarClose = ()=> {
    setErrorMessage("");
  }

  const handleEntryKeyInputChange = (event) => {
    // Handle Input Change on Entry Key Form
    setEntryKey(event.target.value);
  }
  const handleCancelEntryKeyClick = () => {
    // When Cancle Entry Key Form Button is clicked close Entry Key form
    setShowEntryKeyForm(false);
    setEntryKey('');
  }
  const handleEntryKeyButtonClick = async () => {
    // When Add Entry Key Button of Entry Key form is clicked
    setShowEntryKeyForm(false);
    try {
      const response = await axios.patch(`/bookings/${booking.id}/set-door-key/`,{"door_key": entryKey})
      console.log("Entry Key Success: ",response.data);
      setSuccessMessage("Entry Key added successfully!");
      setIsDataLoading(true);
      setEntryKey("");
      setErrorMessage("");
      fetchBookingAgain();
    }
    catch (e) {
      if(e.response?.data?.detail) {
        setErrorMessage(e.response.data.detail)
      }
      else {
        setErrorMessage("Failed to add entry Key. Please try again!")
      }
    }

  }
  const handleCancelBooking = async () => {
    setIsDataLoading(true);
    try {
      const response = await axios.patch(`/bookings/${id}/cancel`);
      console.log("Cancelled Booking: ", response.data);
      setSuccessMessage("Booking successfully cancelled.");
      setErrorMessage("");
      setOpenCancelModal(false);
      fetchBookingAgain();
    }
    catch (e) {
      if(e.response?.data?.detail) {
        setErrorMessage(e.response.data.detail)
      }
      else {
        setErrorMessage("Failed to Cancel Booking. Please try again!")
      }
      setIsDataLoading(false);
    }
  }

  async function bookingCheckIn() {
    setIsDataLoading(true);
    try {
        const response = await axios.patch(`/bookings/${id}/check-in`);
        console.log("CheckIn booking: ", response.data);
        setSuccessMessage("Booking Check In successful");
        setErrorMessage("");
        fetchBookingAgain();
    } catch (error) {
        console.error(error);
        if(error.response?.data?.detail){
          setErrorMessage(error.response.data.detail);
        }
        else {
          setErrorMessage("Error Check In Booking. Please try again!");
        }
        setIsDataLoading(false);
    }
  }
  const handleDownload = async () => {
    try {
      for (const doc of documents) {
        const response = await axios.get(doc.document_url, {
          responseType: 'blob' // Important to handle the file download
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${doc.guest}-${doc.document_type}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('There was an error downloading the files:', error);
    }
    }

  
  async function bookingCheckOut() {
    setIsDataLoading(true);
    try {
        const response = await axios.patch(`/bookings/${id}/check-out`);
        console.log("CheckOut booking: ", response.data);
        setSuccessMessage("Booking Check Out successful");
        setErrorMessage("");
        fetchBookingAgain();
    } catch (error) {
        console.error(error);
        if(error.response?.data?.detail){
          setErrorMessage(error.response.data.detail);
        }
        else {
          setErrorMessage("Error Check Out Booking. Please try again!");
        }
        setIsDataLoading(false);
    }
  }
  function handleOpenCancelModal() {
    setOpenCancelModal(true);
  }
  function handleCloseCancelModal() {
    setOpenCancelModal(false);
  }

  return isDataLoading ? (
    // For showing loading
    <Backdrop
      open={isDataLoading}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : !error && booking ? (
    <>
      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
          <Link underline="hover" color="inherit" component={RouterLink} to={`/`}>
            Home
          </Link>
          <Link underline="hover" color="inherit" component={RouterLink} to={`/bookings/`}>
            All Bookings
          </Link>
          <Typography color="text.primary">Booking No. {booking.id}</Typography>
        </Breadcrumbs>
      </Container>
      <Container maxWidth="xl">
        <Typography variant="h3">Booking Details</Typography>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "0.5rem" }}>
          <Grid item>
            <Typography variant="h5">{booking.name}</Typography>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={0}>
              <IconButton onClick={() => handleEditClick(booking)}>
                <EditIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Container maxWidth="xl"  >
          {/* Booking Details Cards */}
          <Grid container>
            <Grid item sm={12} md={10} sx={{marginBottom:"1.5rem"}}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="CheckIn Time" description={booking.checkin_time} iconNumber={1} firstCard={1}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="CheckOut Time" description={booking.checkout_time} iconNumber={7} secondCard={2}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Start Date" description={booking.start_date} iconNumber={2} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="End Date" description={booking.end_date} iconNumber={8} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Property" description={booking.property_details?.property_name} iconNumber={3} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Status" description={booking.status} iconNumber={6} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Deposit Amount" description={booking.deposit_amount} iconNumber={9} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Refunded Amount" description={booking.payment_details?.refunded} iconNumber={4} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Entry Key" description={booking.door_key || "?"} iconNumber={5} secondLastCard={1}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ItemCard title="Location" description={booking.property_details?.apt_number + ", " + booking.property_details?.building_name + ", " + booking.property_details?.neighbourhood} iconNumber={10} lastCard={1} link={booking.property_details?.property_gmaps_link}/>
                </Grid>
                
              </Grid>
            </Grid>
            <Grid item sm={4} md={2}>
              <Typography variant="h5">Actions</Typography>
              {
                booking.status === "UPCOMING" && (
                  <Button
									onClick={() => bookingCheckIn()}
									variant="contained"
									fullWidth
									sx={{margin:"1rem"}}
									color="primary"
								>
									Check In
								</Button>
                )
                
              }
              {
                booking.status === "ACTIVE" && (
                  <Button
									onClick={() => bookingCheckOut()}
									variant="contained"
									fullWidth
									sx={{margin:"1rem"}}
									color="primary"
								>
									Check Out
								</Button>
                )
                
              }
              {/* When Booking is COMPLETED show Refund Button */}
              { booking.status === "COMPLETED"  && booking.payment_details.status === "REFUNDED" &&
                <Button margin="normal" variant="contained" fullWidth sx={{margin:"1rem"}} color="primary" onClick={() => setShowRefundForm(true)}>Initiate Refund</Button>
                
              }
              {/* When Door Key is not provide show Add Entry Key button */}
              { 
                (booking.status === "UPCOMING" || booking.status === "ACTIVE") && (
                  booking.door_key?
                    <Button margin="normal" variant="contained" fullWidth sx={{margin:"1rem"}} color="primary" onClick={() => setShowEntryKeyForm(true)}>Edit Entry Key</Button>
                  :
                    <Button margin="normal" variant="contained" fullWidth sx={{margin:"1rem"}} color="primary" onClick={() => setShowEntryKeyForm(true)}>Add Entry Key</Button>
                )
              }
              {
                (booking.status !== "CANCELLED" && booking.status !== "COMPLETED" && booking.status !=="AWAITING_CLEANING") && (
                  <Button margin="normal" variant="contained" fullWidth sx={{margin:"1rem"}} color="primary" onClick={() => handleOpenCancelModal()}>Cancel Booking</Button>
                )
              }

{
                documents.length !== 0 && (
                  <Button
                  onClick={() => handleDownload(booking.id)}
                  variant="contained"
                  fullWidth
                  sx={{margin:"1rem"}}
                  color="primary"
                >
                  Download Documents
                </Button>
                )
              }
            </Grid>
          </Grid>
          {/* <Grid container sx={{margin:"20px 0"}}>
            <Grid item xs={12} sm={12} sx={{marginBottom:"0.2rem"}}>
              <ItemCard title="Entry Key" description={booking.door_key} iconNumber={5} contentRightMargin="5.5rem" singleCard={1} />
            </Grid>
          </Grid> */}
        </Container>
        
        <br></br>
        {/* Success message Snackbar */}
        <CustomSnackbar message={successMessage} closeSnackbar={handleSuccessSnackbarClose} severity="success" />
        {/* Error message Snackbar */}
        { errorMessage && <CustomSnackbar message={errorMessage} closeSnackbar={handleErrorSnackbarClose} severity="error" />}
        
        {/* Refund Form */}
        {showRefundForm && (
          <Dialog open={showRefundForm} onClose={handleCancelRefundButtonClick}>
            <DialogTitle sx={{ fontWeight: "bold" }}>Refund</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ textAlign: "center" }}>Enter refund amount:</DialogContentText>
              <TextField
                margin="dense"
                fullWidth
                label="Amount"
                type="number"
                value={amount}
                onChange={handleRefundInputChange}
                inputProps = {{
                  min: 0,

                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRefundButtonClick}>Refund</Button>
              <Button onClick={handleCancelRefundButtonClick}>Cancel</Button>
            </DialogActions>
          </Dialog>
          
        )}
        {/* Entry Key Form */}
        {
          showEntryKeyForm && (
            <Dialog open={showEntryKeyForm} onClose={handleCancelEntryKeyClick}>
              <DialogTitle sx={{ fontWeight: "bold" }}>Add Entry Key</DialogTitle>
              <form onSubmit={handleEntryKeyButtonClick}>
                <DialogContent>
                  <TextField
                    margin="dense"
                    fullWidth
                    label="Entry Key"
                    type="text"
                    value={entryKey}
                    onChange={handleEntryKeyInputChange}
                    required
                  />
                  </DialogContent>
                <DialogActions>
                  <Button type="submit">Add Key</Button>
                  <Button onClick={handleCancelEntryKeyClick}>Cancel</Button>
                </DialogActions>
              </form>
            </Dialog>
          )
        }
      </Container>
      {/* Edit Booking Form */}
      <Dialog open={open} onClose={handleEditClose}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Edit Booking</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center" }}>Edit the booking details:</DialogContentText>
          {editFormError && (
            <Typography color="error" ref={errorRef}>
              {editFormError}
            </Typography>
          )}
          <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            name="start_date"
            InputLabelProps={{
              shrink: true,
            }}
            value={editBooking.start_date}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="End Date"
            type="date"
            fullWidth
            name="end_date"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: editBooking.start_date,
            }}
            value={editBooking.end_date}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="CheckIn Time"
            type="time"
            fullWidth
            name="checkin_time"
            value={editBooking.checkin_time}
            onChange={handleChange}
            inputProps={{
              step: 300, // 5 minutes step
            }}
          />
          <TextField
            margin="dense"
            label="CheckOut Time"
            type="time"
            fullWidth
            name="checkout_time"
            value={editBooking.checkout_time}
            onChange={handleChange}
            inputProps={{
              step: 300, // 5 minutes step
            }}
          />
          <TextField
            margin="dense"
            fullWidth
            label="Deposit Amount"
            type="number"
            name="deposit_amount"
            value={editBooking.deposit_amount}
            onChange={handleChange}
            inputProps = {{
              min: 0,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal open={openCancelModal} handleClose={handleCloseCancelModal} handleConfirm={handleCancelBooking} message={"Are you sure you want to cancel this booking?"} />
    </>
  ) : (
    // Major Errors Box
    <div>{error && <CustomErrorBox errorMessage={error} />}</div>
  );
};

export default Booking;
