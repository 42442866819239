import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "config";
import { useParams } from 'react-router-dom';
import CountryCodeDropdown from "./CountryCodeDropdown"
import TextSection from "./TextSection";
import axios from "api/axios";
import { ApiService } from "api/axios"
import { toast } from 'react-toastify'; // Import Toaster and toast
import useBookingDetails from './useBookingDetails';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Paper,
  Tooltip,
  IconButton,

} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from '@mui/icons-material/Clear';
import StyledList from "./StyledList";
import GuestHeader from "./GuestHeader"
import OuterContainer from "./OuterContainer";
import CustomButton from "./CustomButton";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
const DocumentUpload = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const bookingDetails = useBookingDetails();
  const booking_id = bookingDetails ? bookingDetails.id : null;
  const [phoneStatus , setPhoneSatus] =useState(false)
  const [numGuests, setNumGuests] = useState(1);
  const [maxGuest, setMaxGuest] = useState(4);
  const guestItems = [
    `The property only allows maximum of ${maxGuest ? maxGuest : 1} guests.`,
    'For further assistance you can contact the manager.'
  ];
  
  const [error, setError] = useState(null);

  const [guestData, setGuestData] = useState(
    Array(1).fill({
      fullName: "",
      documentType: "",
      phoneNumber: "",
      dialCode: '',
      documents: [],
      errors: {},
      guestUuid:'',
      UploadField: true,
      fileName: "",
    })
  );

  const incrementGuests = () => {
    if (numGuests < maxGuest) {
      setNumGuests(numGuests + 1);
      setGuestData((prevData) => [
        ...prevData,
        { fullName: "", documentType: "", phoneNumber: "", documents: [], errors: {}, UploadField: true, fileName: "" },
      ]);
    }
  };

  const decrementGuests = () => {
    if (numGuests > 1) {
      setNumGuests(numGuests - 1);
      setGuestData((prevData) => prevData.slice(0, numGuests - 1));
    }
  };

  const handleInputChange = (index, field, value) => {
    if (field === 'phoneNumber') {
      if (value) {
        const phoneNumberObj = parsePhoneNumberFromString(value);
        if (phoneNumberObj && phoneNumberObj.isValid()) {
          setPhoneSatus(true);
          const dialCode = phoneNumberObj.countryCallingCode;
          const dialNumber = phoneNumberObj.nationalNumber;
          setGuestData((prevData) => {
            const newData = [...prevData];
            newData[index] = {
              ...newData[index],
              phoneNumber: dialNumber,
              dialCode: dialCode, // Save dial code
              errors: { ...newData[index].errors, phoneNumber: "" }
            };
            return newData;
          });
  
          setError(null);
        } else {
          setPhoneSatus(false)
          setError('Invalid phone number');
        }
      } else {
        // setError('Phone number is required');
      }
    } else {
      // Handle changes for other fields
      setGuestData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field]: value,
          errors: { ...newData[index].errors, [field]: "" }
        };
        return newData;
      });
    }
  };
  



  const handleFileChange = (event, guestIndex) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'];

    const validFiles = files.filter(file => {
      if (!allowedTypes.includes(file.type)) {
        return false;
      }

      if (file.size > 5 * 1024 * 1024) { // Check if file is larger than 5MB
        setGuestData((prevData) => {
          const newData = [...prevData];
          newData[guestIndex].errors.document = `The file "${file.name}" exceeds the 5MB size limit.`;
          return newData;
        });
        return false;
      }

      return true;
    });

    if (validFiles.length === 0) {
      setGuestData((prevData) => {
        const newData = [...prevData];
        newData[guestIndex].documents = [];
        newData[guestIndex].errors.document = "Please upload valid documents (PDF or image) within 5MB.";
        return newData;
      });
      return;
    }

    setGuestData((prevData) => {
      const newData = [...prevData];
      newData[guestIndex].documents = validFiles;
      newData[guestIndex].errors.document = "";
      newData[guestIndex].UploadField = false;
      newData[guestIndex].fileName = validFiles[0].name;
      return newData;
    });
  };

  // Max Guest API Call Here
  const MaxGuest = async () => {
    try {
      if (booking_id !== null) {
        const response = await axios.get(`${BASE_URL}/properties/${booking_id}/max-guests`);
        setMaxGuest(response.data.max_guests);
      }
    } catch (error) {
      console.error('Error fetching max guests:', error);
    }
  };

  // FetchData From ReviewPage
  const fetchReviewData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/bookings/${uuid}/review-booking`);
      const getData = response.data;

      // Map the API data to match the structure of the guestData state
      const transformedGuestData = getData.guests.map(guest => ({
        fullName: guest.full_name || "",
        documentType: guest.document_type || "",
        phoneNumber: guest.phone_number?.number || "",
        dialCode: guest.phone_number?.dial_code || "",
        guestUuid: guest.uuid,
        documents: guest.documents.map(doc => ({
          uuid: doc.uuid,
          filename: doc.filename,
          url: doc.url,
          // Assuming you want to keep a reference to the file's binary data:
          binaryData: doc.fileData ? new Blob([doc.fileData], { type: doc.fileType }) : null,
        })),
        errors: {},
        UploadField: true,
        fileName: guest.documents.length > 0 ? guest.documents[0]?.filename : "",
      }));

      if (transformedGuestData.length > 0) {
        setGuestData(transformedGuestData);
        setPhoneSatus(true)
      }
      // Optionally set the booking_id if needed
      // setBooking_id(getData.booking_id);

    } catch (error) {
      console.error('Error fetching check-in time:', error);
      toast.error('An error occurred while fetching the data.');
    } finally {
      // setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchReviewData()
  }, []);

  useEffect(() => {
    MaxGuest();
    if (guestData?.length !== 0) {
      setNumGuests(guestData?.length)
    }

  }, [MaxGuest]);




  const handleGetStarted = async () => {
    let hasError = false;
    const updatedGuestData = guestData.map((guest, index) => {
      const errors = {};
      if (!guest.fullName) {
        errors.fullName = "Please enter name.";
        hasError = true;
      }
      if (!guest.documentType) {
        errors.documentType = "Please enter Document Type.";
        hasError = true;
      }
      if (index === 0) {
        if (!guest.phoneNumber || !phoneStatus) {
          errors.phoneNumber = "Please enter phone number.";
          hasError = true;
        }
        if (!guest.dialCode) {
          errors.dialCode = "Please enter an ISD code.";
          hasError = true;
        }
      }
      if (guest.documents.length === 0) {
        errors.document = "Please upload a document.";
        hasError = true;
      }
      return { ...guest, errors };
    });

    setGuestData(updatedGuestData);

    if (hasError) {
      return;
    }

    const formData = new FormData();
    guestData.forEach((guest, index) => {
      if (guest.fullName) {
          formData.append(`guests[${index}].full_name`, guest.fullName);
      }
  
      if (guest.documentType) {
          formData.append(`guests[${index}].document_type`, guest.documentType);
      }
  
      if (phoneStatus && guest.phoneNumber) {
          formData.append(`guests[${index}].phone_number`, guest.phoneNumber);
      }
  
      if (phoneStatus && guest.dialCode) {
          formData.append(`guests[${index}].dial_code`, guest.dialCode);
      }
  
      if (guest.guestUuid) {
          formData.append(`guests[${index}].uuid`, guest.guestUuid);
      }
  
      if (guest.documents && guest.documents.length > 0) {
          guest.documents.forEach((document, docIndex) => {
              if (document) {
                  formData.append(`guests[${index}].documents[${docIndex}]`, document);
              }
          });
      }
  });
    
    try {
      // Display the toast before sending the request
      const toastId = toast.info("Your documents are being processed. Please wait...", {
        autoClose: false, // Prevent it from auto-closing
      });

      const response = await axios.post(`${BASE_URL}/bookings/${uuid}/upload-document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Dismiss the toast after a successful response
      toast.dismiss(toastId);

      // Navigate after dismissing the toast
      navigate(`/bookings/${uuid}/review-booking`);
    } catch (error) {
      console.error("Error fetching booking details:", error);
      // You can also handle the error by displaying another toast if needed
    }
  };

  const renderGuestForm = (guestNumber) => (
    <Paper elevation={3} sx={{ p: 3, mb: 2, display: 'flex', flexDirection: 'column', height: '100%' }} >
      <Typography variant="h4" gutterBottom className='mian-guest' >
        {guestNumber === 1 ? "Main Guest" : `Guest ${guestNumber}`}
      </Typography>
      <p className='text-Font'>Full Name</p>
      {guestData[guestNumber - 1].errors.fullName && (
        <Typography color="error" sx={{ pt: 1 }}>
          {guestData[guestNumber - 1].errors.fullName}
        </Typography>
      )}
      <TextField
        fullWidth
        label=""
        variant="outlined"
        margin="normal"
        value={guestData[guestNumber - 1].fullName}
        onChange={(e) => handleInputChange(guestNumber - 1, "fullName", e.target.value)}
        required
      />
      <p className='text-Font'>Document type</p>
      {guestData[guestNumber - 1].errors.documentType && (
        <Typography color="error" sx={{ pt: 1 }}>
          {guestData[guestNumber - 1].errors.documentType}
        </Typography>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel></InputLabel>
        <Select
          value={guestData[guestNumber - 1].documentType}
          onChange={(e) => handleInputChange(guestNumber - 1, "documentType", e.target.value)}
          required
        >
          <MenuItem value="passport">Passport</MenuItem>
          <MenuItem value="id_card">ID Card</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ mt: 0.8, mb: -0.7 }}>
        <p className="text-Font doc">Document</p>
        {guestData[guestNumber - 1].errors.document && (
          <Typography color="error"  >
            {guestData[guestNumber - 1].errors.document}
          </Typography>
        )}
      </Box>
      {!guestData[guestNumber - 1]?.fileName ? (
        <Button
          variant="outlined"
          component="label"
          fullWidth
          sx={{ justifyContent: "flex-start", color: "text.secondary", mt: 1 }}
          className={guestNumber === 1 ? "Document-upload" : "nextDocumentupload"}
        >
          <div className="cen">
            <span>Upload Document</span>
            <input
              type="file"
              accept=".pdf, image/*"
              hidden
              multiple
              onChange={(event) => {
                handleFileChange(event, guestNumber - 1);
              }}
              required
              sx={{ p: 2 }}
            />
          </div>
        </Button>
      ) : (
        <div className="file-sty">
          <div className="nextCard">
            <p>{guestData[guestNumber - 1]?.fileName}</p>
          </div>
          <div className="icon" style={{ paddingRight: "20px" }}>
            <ClearIcon onClick={() => {
              setGuestData((prevData) => {
                const newData = [...prevData];
                newData[guestNumber - 1].UploadField = true;
                newData[guestNumber - 1].fileName = "";
                newData[guestNumber - 1].documents = [];
                return newData;
              });
            }} />
          </div>
        </div>
      )}

      {guestNumber === 1 && (
        <>
          <Box sx={{ mt: 2 }}>
            <p className="text-Font">Phone Number</p>


            {/* < container > */}
            <Grid sx={{ mt: 1.2 }} item xs={'auto'} md={12} >
              {guestData[guestNumber - 1].errors.phoneNumber && (
                <Typography color="error"  >
                  {guestData[guestNumber - 1].errors.phoneNumber}
                </Typography>
              )}
              <PhoneInput
                fullWidth
                value={guestData[guestNumber - 1].phoneNumber?'+'+guestData[guestNumber - 1].dialCode+guestData[guestNumber - 1].phoneNumber:''}
                onChange={(value) => handleInputChange(guestNumber - 1, 'phoneNumber', value)}
                defaultCountry="AE"
              />
              {error && <div style={{ color: 'red' }}>{error}</div>}
            </Grid>
          </Box>
        </>
      )}
    </Paper>
  );

  return (
    <OuterContainer>
      <GuestHeader link={`/customer/${uuid}/welcome/`} />
      <TextSection
        title="Document Upload"
        description="We require these documents to verify your identity and comply with local regulations and building security protocols."
        footerText="All personal data are protected"
      >
        <Box sx={{ mt: 4, borderBottom: '2px solid #DDDBDB' }}></Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ m: "0px 3px" }} variant="h4" gutterBottom>
              Select Number Of Guests
            </Typography>
            <Box
              sx={{
                border: "1px solid #DEDEDE",
                borderRadius: "4px",
                bgcolor: "#fff",
                ml: { xs: 'auto', md: 8 },
                display: "flex",
                alignItems: "center",

              }}


            >
              {numGuests <= 1 ? (
                <IconButton sx={{ m: "0px 2px", color: '#DEDEDE' }} // sx={{ }}
                // onClick={decrementGuests}

                >
                  <RemoveIcon fontSize="lg" />
                </IconButton>
              ) : <IconButton
                sx={{}}
                onClick={decrementGuests}

              >
                <RemoveIcon fontSize="lg" />
              </IconButton>}

              <span style={{ fontSize: '18px', fontWeight: 500 }}>{numGuests}</span>
              {numGuests < maxGuest ? (
                <IconButton
                  sx={{ m: "0px 2px" }}
                  onClick={incrementGuests}

                >
                  <AddIcon fontSize="lg" />
                </IconButton>
              ) : <IconButton
                sx={{ m: "0px 2px", color: '#DEDEDE' }}
              // onClick={incrementGuests}
              // size="small"
              >
                <AddIcon />
              </IconButton>}


            </Box>
          </Typography>
        </Box>
        <Box >

          <StyledList title="Maximum Guests" items={guestItems} />


        </Box>
        {/* changes made here */}
        <Grid container spacing={4}>
          {[...Array(numGuests)].map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              {renderGuestForm(index + 1)}
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <CustomButton text="Submit" onClick={handleGetStarted} />
        </Box>
      </TextSection>

      <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <Tooltip title="Chat with us" arrow>
          <Button
            variant="contained"
            color="primary"
            sx={{
              minWidth: "auto",
              width: 56,
              height: 56,
              borderRadius: "50%",
            }}
          >
            <ChatBubbleOutlineIcon />
          </Button>
        </Tooltip>
      </Box>
    </OuterContainer>
  );
};

export default DocumentUpload;
