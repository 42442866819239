import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import CustomSnackbar from "components/Snackbar";
import { formatDate, formatTime } from "utils/utilityFunctions";

const JobCard = ({ propertyName, jobDate, jobTime, jobDetails, jobStatus, jobId, jobBookingStatus, setJobs }) => {
  const axios = useAxiosPrivate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // Function to determine text and background color based on job status
  const getStatusStyle = () => {
    switch (jobStatus) {
      case "PENDING":
        return { color: "black", backgroundColor: "gold" };
      case "CHANGED":
        return { color: "black", backgroundColor: "gold" };
      case "ACCEPTED":
        return { color: "white", backgroundColor: "limegreen" };
      case "DECLINED":
        return { color: "white", backgroundColor: "#E64751" };
      case "CANCELLED":
        return { color: "white", backgroundColor: "#E64751" };
      case "COMPLETED":
        return { color: "white", backgroundColor: "dodgerblue" };
      // Add more cases as needed for other statuses
      default:
        return { color: "white", backgroundColor: "dodgerblue" };
    }
  };
  async function updateJob(jobId, jobName) {
    try {
      const jobsResponse = await axios.patch(`/partner/jobs/${jobId}/${jobName}`);
      console.log(jobsResponse.data);
      setJobs((prevJobs) => prevJobs.map((job) => (job.id === jobId ? jobsResponse.data : job)));
      setSuccessMessage(`Job status changed to ${jobName}!`);
    } catch (error) {
      console.error(error);
      if(error.response?.data?.detail){
        setErrorMessage(error.response.data.detail);
      }
      else {
        setErrorMessage(error.message);
      }
    }
  }
  const handleSnackbarClose = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  // Apply styles based on job status
  const statusStyle = getStatusStyle();

  return (
    
    <>
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        {/* Top row: Property Name, Time and Day, Job Status */}
        <Grid container alignItems="center" sx={{ marginBottom: "0.8rem" }}>
          <Grid item xs={6}>
            <Typography variant="h5" align="left" color="primary">
              {propertyName}
            </Typography>
          </Grid>
          <Grid item xs={6} pl={2}>
            <Typography color="grey" align="left">{formatTime(jobTime)}</Typography>
            <Typography color="grey" align="left">{formatDate(jobDate)}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ marginBottom: "2.3rem" }}>
          <Grid item xs={6} align="left">
            <Typography variant="h4">Job Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{ ...statusStyle, padding: "5px 15px", borderRadius: "30px" }}>{jobStatus}</div>
          </Grid>
        </Grid>
        <Grid container alignItems="start" sx={{ marginBottom: "0.8rem" }}>
          <Grid item textAlign="left" xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Apartment:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.apartmentNumber}</Typography>
            <Typography variant="body1" fontWeight="bold">
              Location:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.location}</Typography>
            <Typography variant="body1" fontWeight="bold">
              Bedrooms:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.bedroomNumber}</Typography>
          </Grid>
          <Grid item textAlign="left" xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Building:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.building}</Typography>
            {/* <Typography variant="body1" fontWeight="bold">
              Cleaners:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.numberOfCleaners}</Typography> */}
            <Typography variant="body1" fontWeight="bold">
              Hours:
            </Typography>
            <Typography sx={{ marginBottom: "0.8rem" }}>{jobDetails.numberOfHours}</Typography>
          </Grid>
        </Grid>
        
        {jobStatus === "PENDING" || jobStatus === "CHANGED" ? (
          // When jobStatus is PENDING show ACCEPT and REJECT buttons
          <>
            <Button
              variant="contained"
              onClick={() => updateJob(jobId, "accept")}
              fullWidth
              color="success"
              sx={{ height: "2.5rem", marginBottom: "0.3rem" }}
              to="/">
              Accept
            </Button>
            <Button variant="contained" onClick={() => updateJob(jobId, "decline")} fullWidth color="error" sx={{ height: "2.5rem" }} to="/">
              Decline
            </Button>
          </>
          
        ) : jobStatus === "ACCEPTED" ? (
          
          <Button
            variant="contained"
            onClick={() => updateJob(jobId, "arrived")}
            // disabled={jobBookingStatus !== "AWAITING_CLEANING"}
            disabled={0}
            fullWidth
            color="primary"
            sx={{ height: "5.3rem" }}
            to="/">
            Arrived
          </Button>
          // When jobStatus is ARRIVED show CLEANING button
        ) : jobStatus === "ARRIVED" ? (
          <Button
            variant="contained"
            onClick={() => updateJob(jobId, "cleaning")}
            // disabled={jobBookingStatus !== "AWAITING_CLEANING"}
            disabled={0}
            fullWidth
            color="primary"
            sx={{ height: "5.3rem" }}
            to="/">
            Cleaning
          </Button>
          // When jobStatus is CLEANING show COMPLETED button
        ) : jobStatus === "CLEANING" ? (
          <Button
            variant="contained"
            onClick={() => updateJob(jobId, "complete")}
            // disabled={jobBookingStatus !== "AWAITING_CLEANING"}
            disabled={0}
            fullWidth
            color="primary"
            sx={{ height: "5.3rem" }}
            to="/">
            Completed
          </Button>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
    <CustomSnackbar message={errorMessage} closeSnackbar={handleSnackbarClose} severity="error" />
    <CustomSnackbar message={successMessage} closeSnackbar={handleSnackbarClose} severity="success" />

        </>
  );
};

export default JobCard;
