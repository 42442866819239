import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    LinearProgress,
    Grid,
    TextField,
    Button,
    Paper,
    InputAdornment,
    Tooltip,
    IconButton,
} from '@mui/material';

import CountryCodeDropdown from "./CountryCodeDropdown"
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "config";
import { useParams } from 'react-router-dom';
import axios from 'api/axios';
import LockIcon from '@mui/icons-material/Lock';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import OuterContainer from './OuterContainer';
import GuestHeader from './GuestHeader';
import TextSection from './TextSection';
import CustomButton from './CustomButton';

import { toast } from 'react-toastify';
import Loader from './Loader';
const Review = () => {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [booking_id, setBooking_id] = useState(null);
    const [dummyData, setDummyData] = useState();
    const [loading, setLoading] = useState(true); // State for loading

    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/bookings/${uuid}/review-booking`);
                const getData = response.data;
                console.log(getData, 'getData')
                setBooking_id(getData.booking_id)
                setDummyData(getData.guests);
            } catch (error) {
                console.error('Error fetching check-in time:', error);
                toast.error('An error occurred while fetching the data.');
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchReviewData();
    }, [uuid]);


    const handleDocumentChange = (guestKey, event) => {
        const file = event.target.files[0];
        setDummyData((prevState) => ({
            ...prevState,
            [guestKey]: {
                ...prevState[guestKey],
                document: file ? file.name : '',
            },
        }));
    };

    const handleClearDocument = (guestKey) => {
        setDummyData((prevState) => ({
            ...prevState,
            [guestKey]: {
                ...prevState[guestKey],
                document: '',
            },
        }));
    };

    const handleSubmit = async () => {
        if (booking_id) {
            try {
                const response = await axios.patch(`${BASE_URL}/bookings/${booking_id}/send_email/`);
                // if (response.status === 200) {
                //     console.log(response.data.detail, 'response.data.detail')
                //     toast.success(response.data.detail);
                // } else {
                //     toast.error('Failed to send email.');
                // }
            } catch (error) {
                toast.error('An error occurred while sending the email.');
            }
        }
        navigate(`/bookings/${uuid}/arrival-time`);
    };

    const renderGuestForm = (obj, guestNumber) => {
        console.log(obj, 'jj')
        const isMainGuest = guestNumber === 1;
        const guestKey = isMainGuest ? 'mainGuest' : 'guest2';
        const guestData = dummyData[guestKey];


        return (
            <>
                {obj &&
                    <Paper elevation={3} sx={{ p: 3, mb: 2 }}
                        className={guestNumber === 1 ? "reviewCard1" : "reviewCard2"}>
                        <Typography variant="h3" sx={{}} gutterBottom >
                            {isMainGuest ? 'Main Guest' : `Guest ${guestNumber}`}
                        </Typography>
                        <Tooltip></Tooltip>
                        <TextField
                            disabled={true}
                            fullWidth
                            label=""
                            variant="outlined"
                            margin="normal"
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: '#7A7A7A',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    background: '#F9F9F9',


                                }
                            }}
                            value={obj.documents.map(obj => {
                                return (obj.filename)
                            })}
                            InputProps={{

                                endAdornment: (


                                    <InputAdornment position="end"  >

                                        <IconButton disabled={true} onClick={() => handleClearDocument(guestNumber)}>
                                            <ClearIcon sx={{ color: '#000000' }} />
                                        </IconButton>
                                    </InputAdornment>

                                ),
                            }}
                        />
                        <p className='text-Font ' style={{ marginBottom: '1px' }} >Full Name</p>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            disabled={true}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: '#7A7A7A',
                                    fontSize: '16px',
                                    fontWeight: 500
                                }
                            }}
                            value={obj.full_name}
                        />
                        {guestNumber === 1 && (
                            <>
                                <p className='text-Font' style={{ marginBottom: '1px' }}>Phone Number</p>



                                <TextField
                                    fullWidth
                                    label=""
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    disabled={true}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            color: '#7A7A7A',
                                            fontSize: '16px',
                                            fontWeight: 500
                                        }
                                    }}
                                    value={`${obj.phone_number.dial_code} ${obj.phone_number.number}`}


                                />


                            </>
                        )}
                        {/* {!isMainGuest && (
                 <Button variant="outlined" component="label">
                     Upload Document
                     <input type="file" hidden onChange={(e) => handleDocumentChange(guestKey, e)} />
                 </Button>
             )} */}
                    </Paper>
                }
            </>
        );
    };

    return (
        <OuterContainer>
            <GuestHeader link={`/bookings/${uuid}/document-upload`} />
            <TextSection
                title="Review"
                description="We require these documents to verify your identity and comply with local regulations and building security protocols."
                footerText="All personal data are protected"
            >
                <Box sx={{ mt: 4, mb: 4, borderBottom: '2px solid #DDDBDB' }}></Box>
                {/* chanes made here */}
                <Grid container spacing={4}>
                    {dummyData?.map((obj, index) => (
                        <Grid item xs={12} md={4} key={index} >
                            {renderGuestForm(obj, index + 1)}
                        </Grid>
                    ))}
                </Grid>
                {/* changes made here */}
                <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <CustomButton text="Submit" onClick={handleSubmit}/>
               </Box>
           
            
                <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
                    <Tooltip title="Chat with us" arrow>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                minWidth: "auto",
                                width: 56,
                                height: 56,
                                borderRadius: "50%",
                            }}
                        >
                            <ChatBubbleOutlineIcon />
                        </Button>
                    </Tooltip>
                </Box>



            </TextSection>
        </OuterContainer>



    );


};

export default Review;
