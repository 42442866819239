import React from "react";
import { Box } from "@mui/material";
import backArrow from "../../images/backArrow.png"
import { Link } from "react-router-dom";
function GuestHeader({link}) {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#0052cc",
                    p: 4,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Link to={link}>
                        <img src={backArrow} alt="Logo" style={{ width: "28px" }} />
                    </Link>
                    {/* <img src={"/img/logo.svg"} alt="Logo" style={{ height: "40px" }} /> */}
                </Box>
            </Box>
        </>
    )
}

export default GuestHeader;