import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Typography, Grid, Backdrop, CircularProgress, Container, Link, Breadcrumbs } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomErrorBox from "components/ErrorAlert";

const Customer = () => {
  const { id } = useParams();
  const CUSTOMER_URL = `/customer`;
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  const axios = useAxiosPrivate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(CUSTOMER_URL + `/${id}`);
        setCustomer(response.data);
        setIsDataLoading(false);
      } catch (error) {
        console.error(error);
        if (error.response?.data?.detail) {
          setError(error.response?.data?.detail);
        } else {
          setError("Failed to fetch customer details. Please try again!");
        }
        setIsDataLoading(false);
      }
    }
    fetchData();
  }, [id, CUSTOMER_URL, axios]);

  return isDataLoading ? (
    <Backdrop
      open={isDataLoading}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : !error && customer ? (
    <>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
          <Link underline="hover" color="inherit" component={RouterLink} to={`/`}>
            Home
          </Link>
          <Link underline="hover" color="inherit" component={RouterLink} to={`/customers/`}>
            All Customers
          </Link>
          <Typography color="text.primary">Customer No. {customer.id}</Typography>
        </Breadcrumbs>
      </Container>
      <Container component="main">
        <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "0.5rem" }}>
          <Grid item>
            <Typography variant="h5">{customer.name}</Typography>
          </Grid>
        </Grid>
        <Card
          style={{
            maxWidth: "80%",
            margin: "auto",
            marginTop: "20px",
          }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1">{customer.name}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Email
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1">{customer.email}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1">{customer.contact}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Total Bookings
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1">{customer.total_bookings}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Status
                </Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                { customer.customer_status?.status === "ACTIVE" && customer.customer_status?.active_booking
                  ?
                <Link component={RouterLink} to={`/bookings/${customer.customer_status.active_booking}`} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                  <Typography variant="body1">{customer.customer_status?.status}</Typography>
                </Link>:
                <Typography variant="body1">{customer.customer_status?.status}</Typography>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  ) : (
    <div>{error && <CustomErrorBox errorMessage={error} />}</div>
  );
};

export default Customer;
