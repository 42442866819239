import React from 'react';
import Alert from '@mui/material/Alert';

const CustomErrorBox = ({ errorMessage }) => {
  /* Error message box to display major errors */
  return (
    <Alert
      severity="error"
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: '#ffe0e0',
        color: '#ff0000',
        border: '1px solid #ff0000',
        padding: '10px',
      }}
    >
      {errorMessage}
    </Alert>
  );
};

export default CustomErrorBox;
