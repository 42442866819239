
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, useMediaQuery } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import infoImg from '../../images/info.svg';

function StyledList({ title, items }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const titleAlignment = title === "Maximum Guests" ? 'left' : (isSmallScreen ? 'center' : 'left');

  return (
    <Box>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{
          fontSize: '14px',
          lineHeight: 1.66,
          display: "flex",
          alignItems: 'center',
          justifyContent: titleAlignment,
        }}
      >
        <img src={infoImg} alt="icon" style={{ paddingRight: '5px' }} />
        {title}
      </Typography>
      <List sx={{ ml: 0.8 }}>
        {items.map((item, index) => (
          <ListItem key={index} disableGutters sx={{ mt: -3 }}>
            <Box sx={{ display: 'flex' }}>
              <ListItemIcon style={{ minWidth: 'unset', marginRight: 8 }} sx={{ mt: 1.5 }}>
                <CircleIcon style={{ fontSize: 5, color: "#3D3D3D" }} />
              </ListItemIcon>
              <ListItemText primary={item} />
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default StyledList;
