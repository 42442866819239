import React from 'react';
import {Button} from '@mui/material'; 

const CustomButton = ({ text, onClick , disabled }) => {
  return (
    <Button
      disabled={disabled} 
      variant="contained"
      size="large"
      onClick={onClick}
      sx={{pl:5, pr:5}}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
