import React, { useState, useEffect } from "react";
import JobCard from "./JobCard";
import { Grid } from "@mui/material";
import { Container, Backdrop, CircularProgress, Typography, Link, Breadcrumbs } from "@mui/material";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Link as RouterLink } from "react-router-dom";
import CustomErrorBox from "components/ErrorAlert";

export default function Jobs() {
  const JOBS_URL = `/partner/jobs`;
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState("");
  const axios = useAxiosPrivate();

  useEffect(() => {
    async function fetchJobs() {
      try {
        const jobsResponse = await axios.get(JOBS_URL);
        console.log("hey",jobsResponse.data);
        setJobs(jobsResponse.data);
        setIsDataLoading(false);
      } catch (error) {
        console.error(error);
        if(error.response?.data?.detail){
          setError(error.response.data.detail);
        }
        else {
          setError("Failed to fetch jobs. Please try again!");
        }
        setIsDataLoading(false);
      }
    }
    fetchJobs();
  }, [axios, JOBS_URL]);
  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':');
    return new Date(1970, 0, 1, hours, minutes, seconds);
  };
  const todayDate = new Date().toISOString().split('T')[0];
  const TodaysJobs_yet_to_fiter = jobs.filter(job => {
    const jobDate = job.status === "CHANGED" ? job.late_checkout_request_details?.requested_end_date : job.booking_details.end_date;
    return jobDate === todayDate;
  });


const pendingJobs = TodaysJobs_yet_to_fiter.filter(job => job.status === "PENDING").sort((a, b) => {

  const aTime = parseTime(String(a.booking_details.checkout_time));
  const bTime = parseTime(String(b.booking_details.checkout_time));

  return aTime - bTime;
});
const nonPendingJobs = TodaysJobs_yet_to_fiter.filter(job => job.status !== "PENDING").sort((a, b) => {
  
  const aTime = parseTime(String(a.booking_details.checkout_time));
  const bTime = parseTime(String(b.booking_details.checkout_time));

  return aTime - bTime;
});



// Concatenate pending jobs first, then sorted non-pending jobs
const todaysJobs = [...pendingJobs, ...nonPendingJobs];


  const upcomingJobs_yet_to_filter = jobs.filter(job => {
    const jobDate = job.status === "CHANGED" ? job.late_checkout_request_details?.requested_end_date : job.booking_details.end_date;
    return jobDate !== todayDate;

  }).sort((a, b) => {
    // Compare end_date first
    const aDate = new Date(String(a.booking_details.end_date));
    const bDate = new Date(String(b.booking_details.end_date));
    if (aDate !== bDate) {
      return aDate - bDate;
    }

    // If end_date is the same, compare checkout_time
    const aTime = parseTime(String(a.booking_details.checkout_time));
    const bTime = parseTime(String(b.booking_details.checkout_time));

    if (!aTime || !bTime) {
      // Handle cases where time parsing failed
      return 0; // Maintain current order if parsing failed
    }

    return aTime - bTime;
  });


  const pending_upcoming_Jobs = upcomingJobs_yet_to_filter.filter(job => job.status === "PENDING");
  const non_Pending_upcoming_Jobs = upcomingJobs_yet_to_filter.filter(job => job.status !== "PENDING");
  
  
  
  // Concatenate pending jobs first, then sorted non-pending jobs
  const upcomingJobs = [...pending_upcoming_Jobs, ...non_Pending_upcoming_Jobs];
  return (
    <>
      {isDataLoading ? (
        <Backdrop
          open={isDataLoading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : !error && jobs ? (
        <>
          <Container maxWidth="xl">
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
              <Link underline="hover" color="inherit" component={RouterLink} to={`/`}>
                Home
              </Link>
              <Typography color="text.primary">Jobs</Typography>
            </Breadcrumbs>
          </Container>
          <Container component="main" maxWidth="xl">
            <Typography variant="h3" align="left" my={2}>Today's Jobs</Typography>
            <Grid container spacing={2} mb={3}>
              {todaysJobs.length === 0 && (
                <Typography variant="h5" align="left" my={1} mx={3}>
                  No jobs for today.
                </Typography>
              )}
              {todaysJobs.length > 0 &&
                todaysJobs.map((job) => {
                  const jobDate = job.status === "CHANGED"? job.late_checkout_request_details?.requested_end_date: job.booking_details.end_date;
                  const jobTime = job.status === "CHANGED"? job.late_checkout_request_details?.requested_checkout_time : job.booking_details.checkout_time;
                  return (
                    <Grid key={job.id} item xs={12} sm={6} md={6} lg={4}>
                      {/* JobCard component to display all Jobs List */}
                      <JobCard
                        key={job.id}
                        propertyName={job.property_details.name}
                        jobDate={jobDate}
                        jobTime={jobTime}
                        jobDetails={{
                          location: job.property_details.neighbourhood,
                          apartmentNumber: job.property_details.apt_number,
                          bedroomNumber: job.property_details.num_bedrooms,
                          building: job.property_details.building_name, 
                          numberOfHours: job.property_details.num_cleaning_hours,
                        }}
                        jobStatus={job.status}
                        jobId={job.id}
                        jobBookingStatus={job.booking_details.status}
                        setJobs={setJobs}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Typography variant="h3" align="left" my={2}>Upcoming Jobs</Typography>
            <Grid container spacing={2}>
              {upcomingJobs.length > 0 &&
                upcomingJobs.map((job) => {
                  const jobDate = job.status === "CHANGED"? job.late_checkout_request_details?.requested_end_date: job.booking_details.end_date;
                  const jobTime = job.status === "CHANGED"? job.late_checkout_request_details?.requested_checkout_time : job.booking_details.checkout_time;
                  return (
                    <Grid key={job.id} item xs={12} sm={6} md={6} lg={4}>
                      {/* JobCard component to display all Jobs List */}
                      <JobCard
                        key={job.id}
                        propertyName={job.property_details.name}
                        jobDate={jobDate}
                        jobTime={jobTime}
                        jobDetails={{
                          location: job.property_details.neighbourhood,
                          apartmentNumber: job.property_details.apt_number,
                          bedroomNumber: job.property_details.num_bedrooms,
                          building: job.property_details.building_name,
                          numberOfHours: job.property_details.num_cleaning_hours,
                        }}
                        jobStatus={job.status}
                        jobId={job.id}
                        jobBookingStatus={job.booking_details.status}
                        setJobs={setJobs}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Container>
        </>
      ) : (
        <div>{error && <CustomErrorBox errorMessage={error} />}</div>
      )}
    </>
  );
}
