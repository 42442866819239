import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Grid, IconButton, List, ListItem } from "@mui/material";
import axios from "api/axios";
import CustomErrorBox from "components/ErrorAlert";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const CUSTOMER_ENDPOINT = "/customer";

const CustomerDetails = ({ uuid, payment_status, handleCustomerExistState }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    passport_file: null,
  });
  const [error, setError] = useState(null);
  const [fileSelected, setFileSelected] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        passport_file: e.target.files[0],
      }));
      setFileSelected(e.target.files[0].name);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        passport_file: null,
      }));
      setFileSelected("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.passport_file) {
      setError("You must attach a passport file!");
      return;
    }
    try {
      await axios.post(`${CUSTOMER_ENDPOINT}/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (payment_status === "NOT_APPLICABLE") {
        handleCustomerExistState(2);
      } else {
        handleCustomerExistState(1);
      }
    } catch (error) {
      console.log("LogError: ", error);
      if (error.response?.data?.detail) {
        setError(error.response.data.detail);
      } else {
        setError("Invalid Credentials. Please try again!");
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '18px', sm: '22px' }, textAlign: 'center', mb: 3, color: 'primary.main' }}>
        Guest Check-in Form
      </Typography>
      {error && <CustomErrorBox errorMessage={error} />}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px' }, mt: 1, mb: 0.5, textAlign: 'left' }}>
              Full Name <span style={{color:'black'}}>*</span>
            </Typography>
            <TextField
              name="name"
              placeholder="eg.,Faheem N."
              fullWidth
              value={formData.name}
              onChange={handleChange}
              required
              variant="outlined"
              sx={{ borderRadius: 1, mt: -0.2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px' }, mt: 1, mb: 0.5, textAlign: 'left' }}>
              Email Address <span style={{color:'black'}}>*</span>
            </Typography>
            <TextField
              name="email"
              placeholder="eg.,faheem@gmail.com"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
              variant="outlined"
              sx={{ borderRadius: 1, mt: -0.2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px' }, mt: 1, mb: 0.5, textAlign: 'left' }}>
              
              Phone Number <span style={{color:'black'}}>*</span>
            </Typography>
            <TextField
              name="contact"
              placeholder="eg.,1234567890"
              fullWidth 
              value={formData.contact}
              onChange={handleChange}
              required
              variant="outlined"
              sx={{ borderRadius: 1, mt: -0.2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px' }, mt: 1, mb: 0.1, textAlign: 'left' }}>
              Document Upload <span style={{color:'black'}}>*</span>
            </Typography>
            <Box
              sx={{
                border: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                p: 1,
              }}
            >
              <IconButton
                component="label"
                color="primary"
                sx={{ mr: 1, bgcolor: 'primary.main', color: 'white', borderRadius: 1 }}
              >
                <CloudUploadIcon />
                <input
                  id="passport_file"
                  type="file"
                  name="passport_file"
                  hidden
                  onChange={handleFileChange}
                  accept=".pdf, .png, .jpg, .jpeg"
                />
              </IconButton>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {fileSelected || "Upload Document*"}
              </Typography>
            </Box>
            <List sx={{ mt: 1 }}>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '11px' }}>• Add the passport copy or emirates ID of all the guests staying in the property. This is in adherence to local laws.</Typography>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '11px' }}>• Ensure it is clear and readable.</Typography>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <Typography variant="body2" sx={{ fontSize: '11px' }}>• You can upload a scanned copy or a photo.</Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
            <Button type="submit" variant="contained" color="primary" sx={{ py: 1, px: 4, borderRadius: 1 }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CustomerDetails;