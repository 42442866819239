import React, { useEffect } from 'react';
import useBookingDetails from './useBookingDetails';
import { useNavigate, useParams , Link } from "react-router-dom";
import Loader from './Loader';

function MagicLinkStatus() {
  const bookingDetails = useBookingDetails();
  console.log(bookingDetails,'bookingDetails')
  const navigate = useNavigate();
  const { uuid } = useParams();
  console.log(bookingDetails?.arrival_time)
  useEffect(() => {
    if (bookingDetails) {
      if (bookingDetails.guests.length === 0) {
        navigate(`/customer/${uuid}/welcome`);
      } else {
        if (bookingDetails?.arrival_time) {
          navigate(`/bookings/${uuid}/preCheckin`);
        } else if (bookingDetails.arrival_time !== "") {
          navigate(`/bookings/${uuid}/arrival-time`);
        } else {
          navigate(`/bookings/${uuid}/review-booking`);
        }
      }
    }
  }, [bookingDetails, uuid, navigate]);
  

  return (
    <div>
      <Loader/>
    </div>
  );
}

export default MagicLinkStatus;
