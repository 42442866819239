import React, { useState } from 'react';
import Select from 'react-select';
import CountryFlag from 'react-country-flag';
import countryCodes from 'country-codes-list';

// Create a custom list with only dial codes and country codes
const countryCodeOptions = Object.entries(countryCodes.customList('countryCode', '{countryCallingCode}'))
  .map(([countryCode, dialCode]) => ({
    value: dialCode,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CountryFlag countryCode={countryCode.trim()} svg style={{ marginRight: 10 }} />
        {dialCode}
      </div>
    ),
    countryCode: countryCode.trim()
  }));

// Remove duplicate dial codes
const uniqueCountryCodeOptions = Array.from(new Set(countryCodeOptions.map(option => option.value)))
  .map(uniqueDialCode => countryCodeOptions.find(option => option.value === uniqueDialCode));

const CountryCodeDropdown = ({ value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(
    uniqueCountryCodeOptions.find(option => option.value === value) || null
  );

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption.value);
    console.log(selectedOption.value, 'selectedOption.value');
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={uniqueCountryCodeOptions}
      styles={{
        option: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
         
        }),
        singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          
        }),
      }}
      displayEmpty="true"
    />
  );
};

export default CountryCodeDropdown;
