import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography, Link, Modal, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PoolIcon from '@mui/icons-material/Pool';
import KitchenIcon from '@mui/icons-material/Kitchen';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TextHeading from './GuestComponent/TextHeading';
const iconMap = {
  wifi: (isSelected) => <WifiIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  television: (isSelected) => <TvIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  ac: (isSelected) => <AcUnitIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  parking: (isSelected) => <DirectionsCarIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  indoor_pool: (isSelected) => <PoolIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  kitchen: (isSelected) => <KitchenIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  refrigerator: (isSelected) => <KitchenOutlinedIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  laundry: (isSelected) => <LocalLaundryServiceIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  gym: (isSelected) => <FitnessCenterIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  bar: (isSelected) => <LocalBarIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
  otherServices: (isSelected) => <StarBorderIcon sx={{ width: '45.98px', height: '31.65px', color: isSelected ? "#fff" : "#004AAD" }} />,
};

const Amenities = ({ bookingDetails }) => {
  const [open, setOpen] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = (amenity) => {
    setSelectedAmenity(amenity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAmenity(null);
  };

  useEffect(() => {
    if (bookingDetails && bookingDetails.amenities) {
      setAmenities(bookingDetails.amenities);
    }
  }, [bookingDetails]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const contentWidth = container.scrollWidth;
      setIsOverflowing(contentWidth > containerWidth * 0.8);
    }
  }, [amenities, showMore]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const initialItemsCount = isMobile ? 8 : 10;

  return (
    <Box ref={containerRef}>
       <TextHeading text="Amenities" />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
        {(showMore ? amenities : amenities.slice(0, isMobile ? 8 : 10)).map((amenity) => (
          <Grid
            item
            xs={3}   // 2 items per row on mobile (12 / 6 = 2)
            sm={2.4} // 5 items per row on tablet (12 / 2.4 ≈ 5)
            md={1.2} // 10 items per row on desktop (12 / 1.2 ≈ 10)
            lg={1.2} // Same as md for larger screens
            key={amenity.name}
          >
            <Box
              display="flex"
              padding="10px 5px 10px 5px"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bgcolor={selectedAmenity === amenity ? '#004AAD' : '#fff'}
              color={selectedAmenity === amenity ? '#fff' : '#000'}
              border="1px solid #ddd"
              borderRadius="4px"
              onClick={() => handleOpen(amenity)}
              sx={{ cursor: 'pointer', userSelect: 'none' }}
            >
              {iconMap[amenity.name.toLowerCase().replace(' ', '_')]
                ? iconMap[amenity.name.toLowerCase().replace(' ', '_')](selectedAmenity === amenity)
                : iconMap['otherServices'](selectedAmenity === amenity)}
              <Typography variant="body1" sx={{ fontSize: { xs: '12px' }, pt: 1 }}>
                {amenity.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {isOverflowing && amenities.length > initialItemsCount && (
        <Box display="flex" justifyContent="flex-start">
          <Link
            component="button"
            variant="text"
            onClick={handleShowMore}
            endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {showMore ? 'Show Less' : 'Show More'}
            {showMore ? <ExpandLessIcon sx={{ ml: 1 }} /> : <ExpandMoreIcon sx={{ ml: 1 }} />}
          </Link>
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="amenity-modal-title"
        aria-describedby="amenity-modal-description"
      >
        <Box
          position="absolute"
          top="50vh"
          left="20vw"
          transform="translate(-50%, -50%)"
          bgcolor="background.paper"
          boxShadow={24}
          p={2}
          width="60%"
          sx={{ position: 'relative' }}
          borderRadius="8px"
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon sx={{ color: '#004AAD' }} />
          </IconButton>
          <Typography id="amenity-modal-title" variant="h5" component="h2">
            {selectedAmenity?.name}
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: '#004AAD', mt: 1 }}></Box>
          <Typography id="amenity-modal-description" sx={{ mt: 2 }}>
            {selectedAmenity?.custom_text || 'No additional information available.'}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Amenities;