import axios from "axios";
import { BASE_URL } from "config";

// Default axios instance
export default axios.create({
  baseURL: BASE_URL,
});

// Private axios instance with credentials and authorization
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// Adding an interceptor to include the Authorization header
axiosPrivate.interceptors.request.use((config) => {
  let token = localStorage.getItem('admin_token');
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Another axios instance for public requests
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

// ApiService class for making API requests
export class ApiService {
  static saveStripeInfo(data = {}, uuid) {
    return api.post(`${BASE_URL}/payments/${uuid}/`, data);
  }
}
