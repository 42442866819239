// import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
// import useRefreshToken from "./useRefreshToken";
import useAuth from "../hooks/useAuth";
import { getLocalStorageTokens, getLocalStorageRoles } from "../utils/utilityFunctions";
import axios from "../api/axios";
import { useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
  // Custom hook to make axios api call at protected end points
  const { auth, setAuth, setRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Attaching access token in header using axios request interceptor
    const requestIntercepttor = axios.interceptors.request.use(
      (config) => {
        // check access_token available in auth state variable
        var accessToken = auth;
        // when access_token not available in auth state variable

        if (accessToken === "") {
          var localToken = getLocalStorageTokens();
          // check access_token available in local storage
          if (localToken) {
            setAuth(localToken);
            accessToken = localToken;
          }
          var localRole = getLocalStorageRoles();
          if(localRole) {
            setRole(localRole);
          }
        }
        if (accessToken) {
          config.headers["Authorization"] = `Token ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercepttor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            
            // Unauthorized (expired token)
            if (error?.response?.status === 401) {
              navigate('/login');
                
            }
            return Promise.reject(error);
        }
    );

    return () => {
      axios.interceptors.request.eject(requestIntercepttor);
      axios.interceptors.response.eject(responseIntercepttor);
    };
  }, [auth, setAuth]);
  return axios;
};

export default useAxiosPrivate;
