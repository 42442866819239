import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import { Link, Dialog, Grid, DialogActions, DialogContent, DialogContentText, TextField, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Dot from "components/@extended/Dot";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomSnackbar from "components/Snackbar";
import { copyToClipboard, formatDate, formatTime } from "utils/utilityFunctions";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // const order = comparator(a[0], b[0]);
    // if (order !== 0) {
    //   return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Headings of Bookings Table
const headCells = [
  {
    id: "id",
    align: "center",
    disablePadding: false,
    label: "ID",
  },
  {
    id: "property",
    align: "left",
    disablePadding: false,
    label: "Property",
  },
  // {
  //   id: "stay_dates",
  //   align: "left",
  //   disablePadding: true,
  //   label: "Stay Dates",
  // },
  {
    id: "checkin_time",
    align: "left",
    disablePadding: false,
    label: "CheckIn Time",
  },
  {
    id: "checkout_time",
    align: "left",
    disablePadding: false,
    label: "CheckOut Time",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "cleaning_status",
    align: "left",
    disablePadding: false,
    label: "Cleaning Status",
  },
  {
    id: "customer",
    align: "left",
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "uuid",
    align: "center",
    disablePadding: false,
    label: "Magic Link",
  },
  {
    id: "action",
    align: "center",
    disablePadding: false,
    label: "Action",
  },
];

// Function Return Color Dots Based on Status
const OrderStatus = ({ status }) => {
  let color;
  let description = status;

  switch (description) {
    case "UPCOMING":
      color = "warning";
      description = "Upcoming";
      break;
    case "ACTIVE":
      color = "success";
      description = "Active";
      break;
    case "AWAITING_CLEANING":
      color = "secondary";
      description = "Awaiting Cleaning";
      break;
    case "COMPLETED":
      color = "success";
      description = "Completed";
      break;
    case "CANCELLED":
      color = "error";
      description = "Cancelled";
      break;
    default:
      color = "primary";
      description = status.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{description}</Typography>
    </Stack>
  );
};

// Function Return Color Dots Based on Status
const CleaningStatus = ({ status }) => {
  let color;
  let description = status;

  switch (description) {
    case "PENDING":
      color = "warning";
      description = "Waiting Acceptance";
      break;
    case "ACCEPTED":
      color = "success";
      description = "Accepted";
      break;
    case "DECLINED":
      color = "error";
      description = "Declined";
      break;
    case "COMPLETED":
      color = "success";
      description = "Completed";
      break;
    case "CANCELLED":
      color = "error";
      description = "Cancelled";
      break;
    case "CHANGED":
      color = "warning";
      description = "Waiting Acceptance";
      break;
    default:
      color = "primary";
      description = status.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{description}</Typography>
    </Stack>
  );
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, showProperties } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            // When to Show properties in the Booking Table or not.
            !(!showProperties && headCell.id === "property") && (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}>
                {/* When Heading is UUID and Action then dont Allow Sort */}
                {headCell.id === "uuid" || headCell.id === "action" ? (
                  headCell.label
                ) : (
                  <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function BookingsDataGrid(props) {
  const rows = props.properties;
  // To show properties or not
  const { showProperties } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // To store Refund Amount
  const [amount, setAmount] = useState('');
  // To hide or show refund form
  const [showRefundForm, setShowRefundForm] = useState(false);
  // To store entry key
  const [entryKey, setEntryKey] = useState("");
  // To store Booking Id for Actions like Refund, AddEntryKey,...
  const [bookingId, setBookingId] = useState("");
  // To hide or show Entry key Form
  const [showEntryKeyForm, setShowEntryKeyForm]= useState(false);
  // To show error messages in snackbar
  const [errorMessage, setErrorMessage] = useState("");
  // TO show success messages in snackbar
  const [successMessage, setSuccessMessage] = useState("");
  // To make API calls at protected endpoints
  const axios = useAxiosPrivate();
  const [isLateCheckOutModalOpen, setIsLateCheckOutModalOpen] = useState(false);
  // State variable to store booking details for the modal
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [nextBooking, setNextBooking] = useState(null);

  function CopyTextToClipboard(text) {
    const copyStatus = copyToClipboard(text)
    if (copyStatus) {
      setSuccessMessage("Link Copied To Clipboard!");
    } else {
      setErrorMessage("Error copying to clipboard!");
    }
  }

  const handleSnackbarClose = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleCheckOutButton(id) {
    try {
      const bookingResponse = await axios.patch(`/bookings/${id}/check-out`);

      setSuccessMessage("Checkout successful!");
      props.onBookingChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Checking Out this Booking.");
      }
    }
  }

  async function handleSendEmail(id) {
    try {
      const bookingResponse = await axios.patch(`/bookings/${id}/send_email`);
      setSuccessMessage("Email sent Succesfully");
      setErrorMessage("");
      setTimeout(() => {
        props.onBookingChange();
      }, 5000);
    } catch (error) {
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
        setSuccessMessage("");
      } else {
        setErrorMessage("There was an error in sending the email");
        setSuccessMessage("");
      }
    }
  }


  async function handleCheckInButton(id) {
    try {
      await axios.post(`/bookings/${id}/checkin-status`, { is_checked_in: true });
      const bookingResponse = await axios.patch(`/bookings/${id}/check-in`);     
      setSuccessMessage("CheckIn successful!");
      props.onBookingChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Sending the email in this Booking.");
      }
    }
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );

  const handleEntryKeyInputChange = (event) => {
    setEntryKey(event.target.value);
  }
  const handleCancelEntryKeyClick = () => {
    setShowEntryKeyForm(false);
    setEntryKey('');
  }
  const handleEntryKeyButtonClick = async () => {
    setShowEntryKeyForm(false);
    try {
      const response = await axios.patch(`/bookings/${bookingId}/set-door-key/`,{"door_key": entryKey})
      console.log("Entry Key Success: ",response.data);
      setSuccessMessage("Entry Key added successfully!");
      setEntryKey("");
      setErrorMessage("");
      props.onBookingChange();
    }
    catch (e) {
      if(e.response?.data?.detail) {
        setErrorMessage(e.response.data.detail)
      }
      else {
        setErrorMessage("Failed to add entry Key. Please try again!")
      }
    }

  }

  


  const handleAddEntryKeyButton = (bookingId) => {
    setBookingId(bookingId);
    setShowEntryKeyForm(true);
  }

  const handleInitiateRefundButton = (bookingId) => {
    setBookingId(bookingId);
    setShowRefundForm(true);
  }


  


  const handleRefundButtonClick = async () => {
    setShowRefundForm(false);
    try {
      const response = await axios.post(`/payments/${bookingId}/refund`,{"deposit_amount": amount})
      console.log("Refund Success: ",response.data);
      setSuccessMessage("Refund Successful");
      setAmount("");
      setErrorMessage("");
      props.onBookingChange();
    }
    catch (e) {
      if(e.response?.data?.detail) {
        setErrorMessage(e.response.data.detail)
      }
      else {
        setErrorMessage("Refund Failed!")
      }
    }   
  };

  const handleCancelRefundButtonClick = () => {
    setShowRefundForm(false);
    setAmount('');
  };
  const handleLateCheckOutModalOpen = async(booking) => {
    try {
      const response = await axios.get(`/bookings/next-upcoming-booking/${booking.id}`);
      setNextBooking(response.data);
    }
    catch(e) {
      if(e.response?.data?.detail) {
        setErrorMessage(e.response.data.detail)
      }
      else {
        setErrorMessage("Failed to get next Booking. Try again!");
      }
    }
    setSelectedBooking(booking);
    setIsLateCheckOutModalOpen(true);
  }
  const handleCloseLateCheckOutModal = () => {
    setSelectedBooking(null);
    setNextBooking(null);
    setIsLateCheckOutModalOpen(false);
  };
  async function handleLateRequestApprove(id) {
    try {
      const lateRequestResponse = await axios.patch(`/bookings/approve-late-checkout-owner/${id}/`);
      console.log("Late Request Submit Response: ",lateRequestResponse);
      setSuccessMessage("Late Checkout Request approved!");
      setSelectedBooking(null);
      setNextBooking(null);
      setIsLateCheckOutModalOpen(false);
      props.onBookingChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Approving the request.");
      }
      setSelectedBooking(null);
      setNextBooking(null);
      setIsLateCheckOutModalOpen(false);
    }
  }
  
  async function handleLateRequestDecline(id) {
    try {
      const lateRequestResponse = await axios.patch(`/bookings/decline-late-checkout-owner/${id}/`);
      console.log("Late Request Submit Response: ",lateRequestResponse);
      setSuccessMessage("Late Checkout Request declined!");
      setSelectedBooking(null);
      setNextBooking(null);
      setIsLateCheckOutModalOpen(false);
      props.onBookingChange();
    } catch (error) {
      console.log(error);
      if(error.response?.data?.detail)
      {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("There was an error in Declining the request.");
      }
      setSelectedBooking(null);
      setNextBooking(null);
      setIsLateCheckOutModalOpen(false);
    }
  }

  const handleRefundInputChange = (event) => {
    setAmount(event.target.value);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <CustomSnackbar message={successMessage} closeSnackbar={handleSnackbarClose} severity="success" />
          <CustomSnackbar message={errorMessage} closeSnackbar={handleSnackbarClose} severity="error" />
          

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <EnhancedTableHead order={order} orderBy={orderBy} showProperties={showProperties} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="center">
                        {row.id}
                        <Link color="primary" component={RouterLink} to={`/bookings/${row.id}`} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                          <OpenInNewIcon fontSize="small" style={{ marginLeft: "4px", verticalAlign: "middle" }} />
                        </Link>
                      </TableCell>
                      {showProperties && <TableCell align="left">{row.property_details?.property_name}</TableCell>}
                      {/* <TableCell align="left">
                        {row.start_date}&nbsp;&ndash;&nbsp;{row.end_date}
                      </TableCell> */}
                      <TableCell align="left">{`${formatDate(row.start_date)} ${formatTime(row.checkin_time)}`}</TableCell>
                      <TableCell align="left">{`${formatDate(row.end_date)} ${formatTime(row.checkout_time)}`}</TableCell>
                      <TableCell align="left">
                        <OrderStatus status={row.status} />
                      </TableCell>
                      <TableCell>
                        <CleaningStatus status={row.job_details.status} />
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">{row.guests[0]?.full_name}</Typography>
                        <RouterLink to={"https://wa.me/"+row.customer_details?.customer_phone} target="_blank">{row?.guests[0]?.phone_number}</RouterLink>
                      </TableCell>
                      <TableCell align="center">
                        <ContentCopyIcon
                          onClick={() => CopyTextToClipboard(`${window.location.host}/customer/${row.uuid}`)}
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        {
                          // When Door Key is not provide show Add Entry Key button
                          (!row.door_key && (row.status === "UPCOMING" || row.status === "ACTIVE"))? (
                            <Button onClick={() => handleAddEntryKeyButton(row.id)} size="small" fullWidth variant="contained">
                              Add Entry Key
                            </Button>
                          ) : // When Key added show CheckIn Button
                            row.status === "UPCOMING" ? (
                            
                              
                              <Button onClick={() => handleCheckInButton(row.id)} size="small" fullWidth variant="contained">
                                Check In
                              </Button>
                      
                            ) // When Booking is Active show CheckOut Button
                            : row.status === "ACTIVE" ? (
                                <Button onClick={() => handleCheckOutButton(row.id)} size="small" fullWidth variant="contained">
                                  Check Out
                                </Button>
                              ) : ( // When Booking Completed Show Refund Button
                                  row.status === "COMPLETED" && row.payment_details?.status === "PAID"? (
                                    <Button onClick={() => handleInitiateRefundButton(row.id)} size="small" fullWidth variant="contained">
                                      Refund
                                    </Button>
                                  ) : (
                                    ""
                                  )
                                )
                        }
                        {
                          row.late_request?.status === "REQUESTED" && (
                            <Button sx={{display:"block", marginTop: "2px"}} color="warning" onClick={() => handleLateCheckOutModalOpen(row)} size="small" fullWidth variant="contained">
                              Late Check Out
                            </Button>
                          )
                        }
                        {(!row.email_send  && row.building_details.is_email_allowed && row.status === 'UPCOMING'  && row.customer ) && (
                            <Button  sx={{display:"block", marginTop: "2px"}}  onClick={() => handleSendEmail(row.id)} size="small" fullWidth variant="contained">
                              Send Email
                            </Button>
                        )}                          
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {
        // Entry Key Form
        showEntryKeyForm && (
          <Dialog open={showEntryKeyForm} onClose={handleCancelEntryKeyClick}>
            <DialogTitle sx={{ fontWeight: "bold" }}>Add Entry Key</DialogTitle>
            <form onSubmit={handleEntryKeyButtonClick}>
              <DialogContent>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Entry Key"
                  type="text"
                  value={entryKey}
                  onChange={handleEntryKeyInputChange}
                  required
                />
                </DialogContent>
              <DialogActions>
                <Button type="submit">Add Key</Button>
                <Button onClick={handleCancelEntryKeyClick}>Cancel</Button>
              </DialogActions>
            </form>
          </Dialog>
        )
      }
      {/* Refund Form */}
      {showRefundForm && (
        <Dialog open={showRefundForm} onClose={handleCancelRefundButtonClick}>
          <DialogTitle sx={{ fontWeight: "bold" }}>Refund</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: "center" }}>Enter refund amount:</DialogContentText>
            <TextField
              margin="dense"
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              onChange={handleRefundInputChange}
              inputProps = {{
                min: 0,
                max: 20000,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefundButtonClick}>Refund</Button>
            <Button onClick={handleCancelRefundButtonClick}>Cancel</Button>
          </DialogActions>
        </Dialog>
        
      )}
      {isLateCheckOutModalOpen && (
        <Dialog maxWidth={"xs"} open={isLateCheckOutModalOpen} onClose={handleCloseLateCheckOutModal}>
          <div style={{ border:"3px solid black", padding:  "1rem"}}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingleft:0, paddingRight:0 }}>
              <Typography variant="h4" component="span" sx={{ textAlign: "center", flexGrow: 1 }}>Booking Details</Typography>
              <Button onClick={handleCloseLateCheckOutModal} color="inherit" size="small" startIcon={<CloseIcon />} sx={{ marginLeft: 'auto' }}></Button>
            </DialogTitle>
            <Typography variant="body1" align="center">Customer would like to extend their stay in the property</Typography>
            <DialogContent>
              <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "0.5rem" }}>
                <Grid item xs={6} sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Property</Typography>
                  <Typography variant="body1">{selectedBooking.property_details?.property_name}</Typography>
                </Grid>
                <Grid item xs={6} align="right" sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Booking ID</Typography>
                  {selectedBooking.id}
                  <Link color="primary" component={RouterLink} to={`/bookings/${selectedBooking.id}`} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                    <OpenInNewIcon fontSize="small" style={{ marginLeft: "4px", verticalAlign: "middle" }} />
                  </Link>
                </Grid>
                <Grid item xs={6} sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Check-in</Typography>
                  <Typography variant="body1">{formatDate(selectedBooking.start_date)}</Typography>
                  <Typography variant="body1"> {formatTime(selectedBooking.checkin_time)}</Typography>
                </Grid>
                <Grid item xs={6} align="right" sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Check-out</Typography>
                  <Typography variant="body1">{formatDate(selectedBooking.end_date)}</Typography>
                  <Typography variant="body1"> {formatTime(selectedBooking.checkout_time)}</Typography>
                </Grid>
                <Grid item xs={6} sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Customer</Typography>
                  <Typography variant="body1">{selectedBooking.customer_details?.customer_name}</Typography>
                  <RouterLink to={"https://wa.me/"+selectedBooking.customer_details?.customer_phone} target="_blank">{selectedBooking.customer_details?.customer_phone}</RouterLink>

                </Grid>
                <Grid item xs={6} align="right" sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Requested Check-out</Typography>
                  <Typography variant="body1">{formatDate(selectedBooking.late_request.requested_end_date)}</Typography>
                  <Typography variant="body1">{formatTime(selectedBooking.late_request.requested_checkout_time)}</Typography>
                </Grid>
                <Grid item xs={6} sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Next Check-in</Typography>
                  {nextBooking ? (
                    <>
                      <Typography variant="body1">{formatDate(nextBooking.start_date)}</Typography>
                      <Typography variant="body1">{formatTime(nextBooking.checkin_time)}</Typography>
                    </>
                  ) : "---"
                  }
                </Grid>
                <Grid item xs={6} align="right" sx={{marginBottom: "1rem"}}>
                  <Typography variant="h5">Booking ID</Typography>
                  { nextBooking ? (
                    <>
                        {nextBooking.id}
                      <Link color="primary" component={RouterLink} to={`/bookings/${nextBooking?.id}`} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                        <OpenInNewIcon fontSize="small" style={{ marginLeft: "4px", verticalAlign: "middle" }} />
                      </Link>
                    </>
                  ) : "-"
                  }
                </Grid>
                
              </Grid>
            </DialogContent>
            <DialogActions>
              
              <Button size="small" onClick={() => handleLateRequestApprove(selectedBooking?.late_request?.id)} color="success" sx={{margin : "4px"}} variant="contained">
                  Approve
              </Button>
              <Button size="small" onClick={() => handleLateRequestDecline(selectedBooking?.late_request?.id)}  color="error" sx={{margin : "4px"}} variant="contained">
                  Decline
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  );
}
