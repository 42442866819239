import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Typography,
  Container,
  Grid,

  Backdrop,
  CircularProgress,

  Stack,
  Link,
  Breadcrumbs,
} from "@mui/material";

import CustomersDataGrid from "pages/customers/CustomersDataGrid";
import { Link as RouterLink } from "react-router-dom";
import CustomErrorBox from "components/ErrorAlert";

const Customers = () => {
  const CUSTOMERS_URL = "/customer/";

  const [customers, setCustomers] = useState({});
  const [error, setError] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  const axios = useAxiosPrivate();

  useEffect(() => {
    // Fetch customers when the page loads
    async function fetchData() {
      try {
        const response = await axios.get(CUSTOMERS_URL);
        setCustomers(response.data);
        console.log(response.data);
        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response?.data?.detail) {
          setError(error.response.data.detail);
        } else {
          setError("Failed to fetch customers. Please try again!");
        }
        setIsDataLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {isDataLoading ? (
        <Backdrop
          open={isDataLoading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
              <Link underline="hover" color="inherit" component={RouterLink} to={`/`}>
                Home
              </Link>
              <Typography color="text.primary">Customers</Typography>
            </Breadcrumbs>
          </Container>
          <Container component="main">
            <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "0.5rem" }}>
              <Grid item>
                <Typography variant="h5">All Customers</Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={0}>
                  {/* <Button variant="outlined" color="primary" size="large" onClick={handleCreateClick}>
                    Create
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>
            {/* Errors releted to data fetching */}
            {error && <CustomErrorBox errorMessage={error} />}
            {customers && customers.length > 0 && <CustomersDataGrid properties={customers} />}
          </Container>
        </>
      )}
    </>
  );
};

export default Customers;
